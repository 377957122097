/**
 * Asynchronously fetches the file from the given URL assuming it's JavaScript, and executes it.
 * @param url The URL to fetch.
 * @param success The callback to call on success.
 * @param logger The logger to use for logging.
 */
function asyncRequire(url, success, logger) {
    logger.log('Attempting to asynchronously require URL: ' + url);

    var script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState){  //IE
        script.onreadystatechange = function(){
            if (script.readyState == "loaded" ||
                script.readyState == "complete"){
                logger.log('script finished loading!');
                script.onreadystatechange = null;
                success();
            }
        };
    } else {  //Others
        script.onload = function(){
            logger.log('script finished loading!');
            success();
        };
    }

    script.src = url;
    document.getElementsByTagName('body')[0].appendChild(script);
}

module.exports = asyncRequire;
