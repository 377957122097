/**
 * Configuration-related functionality.
 */

var bowser = require('bowser');
var utils = require('../utils/misc.js');
var manCookie = require('../utils/man-cookie.js');
var constants = require('./constants.js');
var packageConfig = require('../../package.json');


/**
 * Checks the debug config from the player options.
 * @param {object} options Our player options.
 * @returns {*} The updated configuration object with debug mode set (or not).
 */
function checkDebugConfig(options) {
    // are we running in debug or production mode?
    if (!options.hasOwnProperty('debug')) {
        // run in production mode by default
        options.debug = false;
    }
    // is there a debug override in the location URL fragment?
    if (window.location.hash && window.location.hash.length > 1 && window.location.hash.substr(1) == constants.FORCE_DEBUG_HASH) {
        window.console.log('Forcing debug mode');
        options.debug = true;
    }

    return options;
}


/**
 * Validates the feed configuration options.
 * @param {object} options The player configuration options.
 * @returns {{url: string, type: string}} The feed configuration.
 */
function validateFeedConfig(options) {
    var feedUrl, feedType;

    if (typeof options.feed === 'string') {
        feedUrl = options.feed;
        feedType = constants.FEED_TYPE_DEFAULT;
    } else if ((typeof options.feed === 'object') && options.feed.hasOwnProperty('url') && options.feed.hasOwnProperty('type')) {
        feedUrl = options.feed.url;
        feedType = options.feed.type;
    } else {
        throw "Feed property must either be a string or an object (if object, must contain \"url\" and \"type\" properties)";
    }

    // validate our feed type
    if (constants.VALID_FEED_TYPES.indexOf(feedType) == -1) {
        throw "Invalid feed type: "+feedType+". Supported types include: "+constants.VALID_FEED_TYPES.join(", ");
    }

    return {
        url: feedUrl,
        type: feedType
    };
}

/**
 * Checks to make sure that all of the necessary parameters have been supplied for our player.
 * @param {object} options The configuration options supplied to the player.
 * @return {object} The validated, padded configuration options object, populated with defaults wherever possible if not supplied.
 */
function validate(options) {
    if (!options) {
        throw "Please supply a proper configuration object for the player";
    }

    options = validateUrlConfig(options);
    options = validateEnvironmentConfig(options);
    options = validatePlaybackConfig(options);
    options = validatePlayerDimensionsConfig(options);
    options = validateMediaTypeConfig(options);
    options = validatePlayerSelectionConfig(options);
    options = validatePlayerSkinConfig(options);
    options = validateMetaConfig(options);
    options = validateDrmConfig(options);
    options = validateDependencyConfig(options);
    options = validatePlayerOverridesConfig(options);
    options = validateEventsConfig(options);

    return options;
}


function validateUrlConfig(options) {
    // of course, the player must have a file to play
    if (!options.hasOwnProperty("url") || !options.url) {
        throw "The player requires a URL for a manifest/file to be able to play that manifest/file";
    }
    if (!options.hasOwnProperty('live')) {
        options.live = constants.LIVE_MEDIA_DEFAULT;
    }
    return options;
}


function validatePlaybackConfig(options) {
    if (!options.hasOwnProperty('autoStart')) {
        options.autoStart = constants.PLAYBACK_AUTOSTART_DEFAULT;
    }
    return options;
}


function validatePlayerDimensionsConfig(options) {
    // player dimensions
    if (!options.hasOwnProperty('width')) {
        options.width = constants.PLAYER_WIDTH_DEFAULT;
    }
    if (!options.hasOwnProperty('height')) {
        if (!options.hasOwnProperty('aspectRatio')) {
            options.aspectRatio = constants.PLAYER_ASPECT_RATIO_DEFAULT;
            options.height = null;
        } else {
            options.height = constants.PLAYER_HEIGHT_DEFAULT;
        }
    }
    if (!options.hasOwnProperty('stretching')) {
        options.stretching = constants.PLAYER_STRETCHING_DEFAULT;
    }
    return options;
}


function validateMediaTypeConfig(options) {
    // what kind of media are we expecting?
    if (!options.hasOwnProperty("mediaType") || options.mediaType == null) {
        options.mediaType = constants.MEDIA_TYPE_AUTO;
    }
    if (constants.VALID_MEDIA_TYPES.indexOf(options.mediaType) == -1) {
        throw "Invalid media type: "+options.mediaType+" (valid types include: "+constants.VALID_MEDIA_TYPES.join(", ")+")";
    }
    if (options.mediaType == constants.MEDIA_TYPE_AUTO) {
        options.mediaType = inferMediaType(options.url);
    }
    return options;
}


function validatePlayerSelectionConfig(options) {
    // player selection
    if (!options.hasOwnProperty("player")) {
        options.player = constants.PLAYER_DEFAULT;
    }
    if (constants.VALID_PLAYERS.indexOf(options.player) == -1) {
        throw "Invalid player specified: "+options.player+" (valid players include: "+constants.VALID_PLAYERS.join(", ")+")";
    }
    if (options.player == constants.PLAYER_FLASH) {
        if (!options.hasOwnProperty('feed') || !options.feed) {
            throw "The DStv Flash player requires the options.feed parameter to be set in its configuration";
        }
    }
    return options;
}


function validatePlayerSkinConfig(options) {
    // skin selection for the player
    if (!options.hasOwnProperty("skin")) {
        options.skin = constants.SKIN_DEFAULT;
    }
    if (constants.VALID_SKINS.indexOf(options.skin) == -1) {
        throw "Invalid player skin: "+options.skin+" (valid skins include: "+constants.VALID_SKINS.join(", ")+")";
    }
    return options;
}


function validateMetaConfig(options) {
    // metadata
    if (!options.hasOwnProperty('meta')) {
        options.meta = null;
    }
    // the (poster) image to display prior to playback
    if (!options.hasOwnProperty("image")) {
        options.image = null;
    }
    return options;
}


function validateDrmConfig(options) {
    // if we're making use of DRM
    if (options.hasOwnProperty("drm") && options.drm) {

        // attempt to infer as much of the DRM info as possible from the URL
        options = utils.stripDrmConfigFromUrl(options);

        if (!options.drm.hasOwnProperty("contentId")) {
            throw constants.DRM_NO_CONTENT_ID_MESSAGE;
        }
        if (!options.drm.hasOwnProperty("applicationId")) {
            options.drm.applicationId = constants.APPLICATION_ID_DEFAULT;
        }
        if (!options.drm.hasOwnProperty("crmId")) {
            options.drm.crmId = constants.CRM_ID_DEFAULT;
        }
        if (!options.drm.hasOwnProperty("accountId")) {
            options.drm.accountId = constants.ACCOUNT_ID_DEFAULT;
        }
        if (!options.drm.hasOwnProperty("profile")) {
            options.drm.profile = constants.DRM_PROFILE_DEFAULT;
        } else if (constants.VALID_DRM_PROFILES.indexOf(options.drm.profile) == -1) {
            throw "Invalid DRM profile: "+options.drm.profile+" (valid profiles include: "+constants.VALID_DRM_PROFILES.join(", ")+")";
        }

        options = populateManCookie(options);
        options = correctDrmContentId(options);
        options = validateDrmServer(options);

    } else {
        options.drm = false;
    }
    return options;
}

function populateManCookie(options) {
    return manCookie.populateManCookie(options);
}

function correctDrmContentId(options) {
    if (options.hasOwnProperty('drm') && options.drm && options.drm.hasOwnProperty('contentId') && !options.live) {
        var extSuffixLen = constants.DRM_VOD_CONTENT_ID_SUFFIX.length;
        var contentIdLen = options.drm.contentId.length;
        var contentIdSuffix = options.drm.contentId.substr(contentIdLen-extSuffixLen, contentIdLen-1);
        // append the content ID suffix
        if (contentIdSuffix != constants.DRM_VOD_CONTENT_ID_SUFFIX) {
            options.drm.contentId += constants.DRM_VOD_CONTENT_ID_SUFFIX;
        }
    }
    return options;
}

function validateDrmServer(options){
    if (!options.hasOwnProperty('hostname')) {
        throw "There is no hostname defined for license requests";
    }

    if (!options.drm.hasOwnProperty("server")) {
        options.drm.server = constants.DRM_SERVERS['dstv'];
    }
    return options;
}

function validateEnvironmentConfig(options) {
    if (!options.hasOwnProperty('env')) {
        if (typeof window !== 'undefined') {
            // look up the environment by way of its hostname, otherwise fall back to production
            options.env = utils.getOrDefault(constants.ENV_HOSTS, window.location.hostname, constants.ENV_PRODUCTION);
        } else {
            options.env = constants.ENV_PRODUCTION;
        }
    }
    if (constants.VALID_ENV_TYPES.indexOf(options.env) == -1) {
        throw "Invalid environment: "+options.env+" (valid environments include: "+constants.VALID_ENV_TYPES.join(", ")+")";
    }
    return options;
}


function validateDependencyConfig(options) {
    if (!options.hasOwnProperty('dependencyBasePath')) {
        // use the environment-based dependency base path, using the current version
        if (options.env == constants.ENV_LOCAL) {
            options.dependencyBasePath = constants.DEPENDENCY_URL_BASE_LOCAL;
        } else {
            options.dependencyBasePath = constants.DEPENDENCY_URL_BASE_DEFAULT +
                constants.ENV_URLS[options.env] + '/' +
                packageConfig.version;
        }
    }
    // no trailing slashes please
    options.dependencyBasePath = utils.stripTrailingSlashes(options.dependencyBasePath);

    // external dependencies for our player
    if (!options.hasOwnProperty('dependencies')) {
        options.dependencies = constants.PLAYER_DEPENDENCIES[options.player];
    }
    // make sure our dependencies are all absolute URLs
    options.dependencies = utils.makeAbsoluteUrls(options.dependencies, options.dependencyBasePath);
    return options;
}


function validatePlayerOverridesConfig(options) {
    // player-specific overrides
    if (!options.hasOwnProperty('overrides')) {
        options.overrides = null;
    }
    return options;
}


function validateEventsConfig(options) {
    if (!options.hasOwnProperty('events')) {
        options.events = {};
    }
    return options;
}

/**
 * Attempts to get the player-specific option with the given name.
 * @param {object} options The player configuration options.
 * @param {string} propName The name of the property.
 * @param {*} defVal The default value, if the property hasn't been set.
 * @returns {*}
 */
function getPlayerOpt(options, propName, defVal) {
    return options.hasOwnProperty(propName) ?
        options[propName] :
        ((options.overrides && options.overrides.hasOwnProperty(propName)) ? options.overrides[propName] : defVal);
}


/**
 * Attempts to get a meta data-specific option value.
 * @param {object} options The player configuration options.
 * @param {string} propName The name of the meta data property.
 * @param {*} defVal The default value, if the property hasn't been set.
 * @returns {*}
 */
function getMetaOpt(options, propName, defVal) {
    return (options.meta && options.meta.hasOwnProperty(propName)) ?
        options.meta[propName] :
        getPlayerOpt(options, propName, defVal);
}


/**
 * Helper function to attempt to infer the media type from the given URL based on its extension.
 * @param {string} url The full URL for the medium.
 * @param {object} logger Our logging configuration.
 * @returns {*} On success, the inferred media type (string). On failure, returns null.
 */
function inferMediaType(url, logger) {
    var mediaType;
    var ext = utils.extractUrlExtension(url);
    if (!ext) {
        if (logger) {
            logger.error("Cannot infer media type from non-existent URL extension. URL must have a file extension to be automatically inferred.");
        }
        return null;
    }
    if (constants.VALID_MEDIA_TYPE_EXTS.indexOf(ext) == -1) {
        if (logger) {
            logger.error("Unsupported media file extension: "+ext+" (supported types include: "+constants.VALID_MEDIA_TYPE_EXTS.join(", ")+")");
        }
        return null;
    }
    // infer the final media type from the extension
    mediaType = constants.MEDIA_TYPE_EXTS[ext];
    if (logger) {
        logger.log('Inferred media type from file extension: '+mediaType+' ('+ext+')');
    }
    return mediaType;
}


/**
 * Constructs the URL for the source file for the player, based on the given URL
 * and the media type in the options.
 * @param {object} options The player configuration options.
 * @param {object} logger Our logging configuration.
 * @returns {string} The source URL for the player to use.
 */
function constructSourceUrl(options, logger) {
    var finalMediaType = options.mediaType || constants.MEDIA_TYPE_AUTO;
    var sourceUrlParts = options.url.split('?', 1);
    var sourceUrlQuery = sourceUrlParts.length > 1 ? '?'+sourceUrlParts[1] : '';
    var sourceUrl = utils.stripTrailingSlashes(sourceUrlParts[0]);

    logger.log('constructSourceUrl(): Initial media type = '+finalMediaType);

    // if we should automatically configure our media type based on the file extension
    if (finalMediaType == constants.MEDIA_TYPE_AUTO) {
        logger.log('Attempting to automatically infer media type');
        finalMediaType = inferMediaType(sourceUrl, logger);
        if (finalMediaType == null) {
            throw "Failed to infer media type";
        }
    }

    // if it's dynamic, modify it to suit the target browser
    if (finalMediaType == constants.MEDIA_TYPE_DYNAMIC) {
        // if we're in Safari
        if (bowser.safari) {
            // go with HLS
            sourceUrl += '/.'+constants.MEDIA_TYPE_EXT_HLS;
        } else {
            // otherwise go with MPEG DASH
            sourceUrl += '/.'+constants.MEDIA_TYPE_EXT_MPEG_DASH;
        }
        logger.log('Dynamically inferring manifest URL as: '+sourceUrl);
    }

    sourceUrl += sourceUrlQuery;
    logger.log('Final constructed URL: '+sourceUrl);
    return sourceUrl;
}

module.exports = {
    checkDebugConfig: checkDebugConfig,
    validateFeedConfig: validateFeedConfig,
    validate: validate,
    getPlayerOpt: getPlayerOpt,
    getMetaOpt: getMetaOpt,
    inferMediaType: inferMediaType,
    constructSourceUrl: constructSourceUrl
};