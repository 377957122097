/**
 * Parser for our default DStv content JSON feeds.
 */

var utils = require('../../utils/misc.js');
var constants = require('../../config/constants.js');

var BRANDS_LOOKUP = {};
BRANDS_LOOKUP['a_ondemand'] = constants.SKIN_DSTVNOW;
BRANDS_LOOKUP['dstvnow'] = constants.SKIN_DSTVNOW;

BRANDS_LOOKUP['a_boxoffice'] = constants.SKIN_BOXOFFICE;
BRANDS_LOOKUP['boxoffice'] = constants.SKIN_BOXOFFICE;

BRANDS_LOOKUP['supersport'] = constants.SKIN_SUPERSPORT;
BRANDS_LOOKUP['freevod'] = constants.SKIN_FREEVOD;

/**
 * Parses the resulting feed from the current DStv feeds.
 * @param {object} options The player configuration options.
 * @param {object} data The data returned from the feed (already parsed from JSON).
 * @param {object} logger Our logging configuration.
 * @returns {*}
 */
function jsonSimpleParser(options, data, logger) {
    logger.log('Parsing data as a simple JSON metadata feed...');

    if (!data || typeof data !== 'object' || !data.hasOwnProperty('result')) {
        logger.error(data);
        throw 'Invalid data for simple JSON feed parser';
    }

    options = parseUserConfig(options, data.result);
    options = parseDrmConfig(options, data.result);
    options = parseAdsConfig(options, data.result);
    options = parsePlayerConfig(options, data.result);
    options = parseMeta(options, data.result);

    logger.log('Resulting configuration after parsing:', options);
    return options;
}


function parseUserConfig(options, feedData) {
    if (feedData.hasOwnProperty('controls')) {
        var controls = feedData.controls;
        options.connectId = utils.getOrDefault(controls, 'connectId', null);
    }
    return options;
}

function parseDrmConfig(options, feedData) {
    if (options.hasOwnProperty('drm') &&
        options.drm &&
        feedData.hasOwnProperty('services') &&
        feedData.services &&
        feedData.services.hasOwnProperty('videoURL')
    ) {
        options = utils.stripDrmConfigFromUrlForFeed(options, feedData);

        if ((typeof options.drm === 'object') && !options.drm.hasOwnProperty('contentId')) {
            if (feedData.hasOwnProperty('metadata') && feedData.metadata.hasOwnProperty('manItemId')) {
                options.drm.contentId = feedData.metadata.manItemId;
            } else {
                options.drm.contentId = utils.extractFilenameFromUrl(feedData.services.videoURL);
            }
        }
    }
    return options;
}

function parseAdsConfig(options, feedData) {
    if (feedData.hasOwnProperty('controls')) {
        var controls = feedData.controls;
        if (controls.hasOwnProperty('ads') &&
            controls.ads != null &&
            (controls.ads.hasOwnProperty('preAdURL') ||
            controls.ads.hasOwnProperty('postAdURL'))
        ) {
            options.ads = controls.ads;
        }
    }
    return options;
}

function parsePlayerConfig(options, feedData) {
    if (feedData.hasOwnProperty('services')) {
        var services = feedData.services;
        options.image = utils.getOrDefault(services, 'imageURL', null);
        options.url = utils.getOrDefault(services, 'videoURL', null);
    }

    if (feedData.hasOwnProperty('settings')) {
        var settings = feedData.settings;
        options.autoStart = utils.getOrDefault(settings, 'autoStart', constants.PLAYBACK_AUTOSTART_DEFAULT);
        options.live = utils.getOrDefault(settings, 'live', constants.LIVE_MEDIA_DEFAULT);
    }
    
    if (options.hasOwnProperty('skin')) {
        options.skin = BRANDS_LOOKUP[options.skin];
    } else if (feedData.hasOwnProperty('controls')) {
        var controls = feedData.controls;
        if (controls.hasOwnProperty('brand') && BRANDS_LOOKUP.hasOwnProperty(controls.brand)) {
            options.skin = BRANDS_LOOKUP[controls.brand];
        } else {
            options.skin = constants.SKIN_DSTVNOW;
        }
    } else {
        options.skin = constants.SKIN_DSTVNOW;
    }

    return options;
}


function parseMeta(options, feedData) {
    if (!options.meta) {
        options.meta = {};
    }

    if (feedData.hasOwnProperty('controls')) {
        var controls = feedData.controls;
        options.meta.base36ID = controls.connectId;
    }

    if (feedData.hasOwnProperty('settings')) {
        var settings = feedData.settings;
        var categoryVideo = 'dstv.category.video';
        var categoryVideoLive = 'dstv.category.video.live';
        var categoryVideoDownload = 'dstv.category.video.download';
        if (settings.live == true) {
            options.meta.videoType = categoryVideoLive;
        } else {
            options.meta.videoType = categoryVideo;
        }
    }

    if (feedData.hasOwnProperty('metadata')) {
        var meta = feedData.metadata;
        options.meta.contentId = utils.getOrDefault(meta, 'contentId', null);
        options.meta.genre = utils.getOrDefault(meta, 'genre', null);
        options.meta.subGenre = utils.getOrDefault(meta, 'subGenre', null);
        options.meta.rating = utils.getOrDefault(meta, 'rating', null);
        options.meta.accessType = utils.getOrDefault(meta, 'accessType', null);
        options.meta.channelName = utils.getOrDefault(meta, 'channelName', null);
        options.meta.channelNumber = utils.getOrDefault(meta, 'channelNumber', null);
    }

    if (feedData.hasOwnProperty('menu')) {
        var menu = feedData.menu;
        if (typeof menu === 'object' && menu.hasOwnProperty('details')) {
            var details = menu.details;
            options.meta.title = utils.getOrDefault(details, 'title', null);
            options.meta.description = utils.getOrDefault(details, 'description', null);
            options.meta.season = utils.getOrDefault(details, 'season', null);
            options.meta.episode = utils.getOrDefault(details, 'episode', null);
            options.meta.category = utils.getOrDefault(details, 'category', null);
            options.meta.subCategory = utils.getOrDefault(details, 'subcategory', null);
            options.meta.product = utils.getOrDefault(details, 'product', null);
        }
    }

    if (!options.meta.hasOwnProperty('site')) {
        options.meta.site = document.location.hostname;
    }

    if (!options.meta.hasOwnProperty('playerName')) {
        options.meta.playerName = constants.PLAYER_NAME;
    }
    
    if (!options.meta.hasOwnProperty('playerVersion')) {
        options.meta.playerVersion = constants.BUILD_VERSION;
    }

    return options;
}


module.exports = jsonSimpleParser;
