/**
 * Rendering custom html for errors
 */

var $ = require('jquery');
var utils = require('./misc.js');
var constants = require('../config/constants.js');

function message (el, message) {
    $('#'+el).html('<div class="multi-drm-error">' + message + '</div>');

    insertMultiDrmErrorCss();
}

function messageWithErrorCode (el, message, code) {
    $('#'+el).html('<div class="multi-drm-error">' + message + ' Error code: ' + code + '.</div>');

    insertMultiDrmErrorCss();
}

function messageForHostname (el, message, hostname) {
    var url = (hostname.indexOf('boxoffice.dstv') != -1) ? constants.FAQ_LINK_BOXOFFICE : constants.FAQ_LINK_DSTVNOW;
    var linkmessage = 'not supported';

    messageWithUrl(el, message, url, linkmessage);
}


function messageWithUrl (el, message, url, linkmessage) {
    $('#'+el).html('<div class="multi-drm-error">' + message + '<a href="' + url + '" target="_blank">' + linkmessage + '</a>.' + '</div>');

    insertMultiDrmErrorCss();
}

function insertMultiDrmErrorCss () {
    $('.multi-drm-error').css({
        'padding': '18%',
        'text-align': 'center',
        'color': '#fff',
        'line-height': '1.45em',
        'font-size': '1.375em',
        'font-smoothing': 'antialiased',
        'text-shadow': '0px 2px 3px rgba(0, 0, 0, 0.3)'
    });
    $('.multi-drm-error a').css({
        'color': 'rgb(0, 149, 218)'
    });
}

module.exports = {
    message: message,
    messageWithErrorCode: messageWithErrorCode,
    messageForHostname: messageForHostname
};