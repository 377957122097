/**
 * Logging-related functionality.
 */

/**
 * Logs the given message to the console.
 * @param {string} msg The message to log to the console
 * @param {object} obj An optional object to output to the console.
 */
function debugLogger(msg, obj) {
    window.console.log(msg);
    if (obj) {
        window.console.log(obj);
    }
}


/**
 * Logs the given error message to the console.
 * @param {string} msg The message to log to the console
 * @param {object} obj An optional object to output to the console.
 */
function errorLogger(msg, obj) {
    window.console.error(msg);
    if (obj) {
        window.console.log(obj);
    }
}


/**
 * Does nothing with the given message (usually for production-mode logging).
 * @param msg
 * @param obj
 */
function emptyLogger(msg, obj) {}


/**
 * Builds up our logging configuration.
 * @param debug
 * @returns {{log: *, error: errorLogger}}
 */
function configureLogging(debug) {
    return {
        log: debug ? debugLogger : emptyLogger,
        error: errorLogger
    };
}


/**
 * Builds an empty logging configuration.
 * @returns {{log: emptyLogger, error: emptyLogger}}
 */
function emptyLogging() {
    return {
        log: emptyLogger,
        error: emptyLogger
    };
}


module.exports = {
    configureLogging: configureLogging,
    emptyLogging: emptyLogging
};
