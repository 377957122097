/**
 * Graylog implementation class for tracking.
 */

var $ = require('jquery');
var constants = require('../config/constants.js');
var asyncRequire = require('../utils/async-require.js');

var graylogData;
var isGrayLogTracking = constants.ANALYTICS_GRAYLOG_ENABLE;

function setupData(object, logger) {
    if (isGrayLogTracking == false) {
        logger.log('// Analytics disabled: Graylog');
    } else {
        initGrayLogScripts(object, logger);
    }
};

function track(action) {
    if (typeof graylogData !== 'undefined') {
        graylogData.action = action;
        UploadData(JSON.stringify(graylogData));
    }
}

function error(message) {
    if (typeof graylogData !== 'undefined') {
        graylogData.action = 'error';
        graylogData.errorMessage = message;
        UploadData(JSON.stringify(graylogData));
    }
}

function initGrayLogScripts(object, logger) {
    if (typeof SSOResolve === typeof Function) {
        logger.log('// Analytics: Graylog script already loaded');
        configureGraylogData(object, logger);
    } else if (typeof constants.ANALYTICS_GRAYLOG_URL !== 'undefined') {
        logger.log('// Analytics: Loading Graylog script');
        asyncRequire(
            constants.ANALYTICS_GRAYLOG_URL,
            function() { configureGraylogData(object, logger); },
            logger
        );
    } else {
        logger.log('// Analytics disabled: Url to Graylog script not defined');
    }
}

function configureGraylogData(object, logger) {
    graylogData = {};
    // Create properties that have values
    if (object.base36ID) graylogData.base36ID = object.base36ID;
    if (object.contentId) graylogData.contentId = object.contentId;

    if (object.title) graylogData.title = object.title;
    if (object.season) graylogData.season = object.season;
    if (object.episode) graylogData.episode = object.episode;
    if (object.channelName) graylogData.channelName = object.channelName;
    if (object.channelNumber) graylogData.channelNumber = object.channelNumber;
    if (object.rating) graylogData.rating = object.rating;
    if (object.category) graylogData.category = object.category;
    if (object.subcategory) graylogData.subcategory = object.subcategory;
    if (object.genre) graylogData.genre = object.genre;
    if (object.subGenre) graylogData.subGenre = object.subGenre;
    if (object.videoType) graylogData.videoType = object.videoType;
    if (object.rating) graylogData.rating = object.rating;

    if (object.site) graylogData.site = object.site;
    if (object.playerName) graylogData.playerName = object.playerName;
    if (object.playerVersion) graylogData.playerVersion = object.playerVersion;
    if (object.product) graylogData.product = object.product;

    logger.log('// Graylog Data:', graylogData);
}

/**
 * Overrides Graylog UploadData Method Update Ticket
 * https://ssl.dstv.com/connect/sso/Scripts/AuthScript.ashx?minify=false
 */
function UploadData(dataJson, _beginCallback, _timeoutCallback, _originURL) {
    if (typeof (_beginCallback) !== 'undefined' && _beginCallback != null)
        _beginCallback();
    $.ajax({
        url: SSOhandlerPath,
        data: { method: "UploadData", dataJson: dataJson, originURL: _originURL || location.href, referrer: document.referrer },
        timeout: glob_SSOTimeOut,
        cache: false,
        dataType: 'jsonp',
        success: function (data) {
            if (typeof (_UploadDataSuccessCallback) !== 'undefined' && _UploadDataSuccessCallback != null)
                _UploadDataSuccessCallback();
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            if (textStatus === "timeout") {
                if (typeof (_timeoutCallback) !== 'undefined' && _timeoutCallback != null)
                    _timeoutCallback();
            } else {
                if (typeof (_UploadDataFailureCallback) !== 'undefined' && _UploadDataFailureCallback != null)
                    _UploadDataFailureCallback(XMLHttpRequest, textStatus, errorThrown); //Might as well send all these parameters
            }
        }
    });
}

module.exports = {
    setupData: setupData,
    track: track,
    error: error
};