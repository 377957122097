module.exports={
  "name": "dstv-player-js",
  "version": "1.0.266",
  "description": "DStv HTML5 Video Player",
  "main": "gulpfile.js",
  "scripts": {
    "test": "echo \"Error: no test specified\" && exit 1",
    "dev": "lite-server"
  },
  "repository": {
    "type": "git",
    "url": "git+https://bitbucket.org/dstvonline/dstv-player-js.git"
  },
  "author": "",
  "license": "ISC",
  "homepage": "https://bitbucket.org/dstvonline/dstv-player-js#readme",
  "devDependencies": {
    "browserify": "13.1.0",
    "del": "2.2.2",
    "gulp": "3.9.1",
    "gulp-clean": "0.3.2",
    "gulp-cssmin": "0.1.7",
    "gulp-debug": "2.1.2",
    "gulp-eslint": "3.0.1",
    "gulp-jasmine": "2.4.1",
    "gulp-less": "3.1.0",
    "gulp-lesshint": "2.0.0",
    "gulp-mustache": "2.2.0",
    "gulp-rename": "1.2.2",
    "gulp-replace": "0.5.4",
    "gulp-sourcemaps": "1.6.0",
    "gulp-uglify": "2.0.0",
    "gulp-util": "3.0.7",
    "lite-server": "2.2.2",
    "mustache": "2.2.1",
    "vinyl-buffer": "1.0.0",
    "vinyl-source-stream": "1.1.0"
  },
  "dependencies": {
    "bowser": "1.4.5",
    "jquery": "2.2.4",
    "js-cookie": "2.1.3",
    "shaka-player": "2.0.0"
  }
}
