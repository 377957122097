/**
 * Constants to be used throughout the DStv HTML5 player (Multi-DRM player).
 */

module.exports = {
    // Constants for event behaviour
    PULSE_DURATION_IN_SECONDS: 15,
    MAN_COOKIE_DURATION_IN_SECONDS: 5,
    LIVE_DURATION_FOR_STALLED_CHECK: 82800000, // 23 * 60 * 60 * 1000 = 82800000

    // Player name and BUILD_VERSION
    PLAYER_NAME: 'dstvplayerjs',
    BUILD_VERSION: '{{BUILD_VERSION}}',

    // For data feeds
    FEED_TYPE_JSON_SIMPLE: "json-simple",
    FEED_TYPE_DEFAULT: "json-simple",
    VALID_FEED_TYPES: ["json-simple"],

    // Media types
    MEDIA_TYPE_AUTO: "auto",
    MEDIA_TYPE_MP4: "mp4",
    MEDIA_TYPE_DYNAMIC: "dynamic",
    MEDIA_TYPE_HLS: "hls",
    MEDIA_TYPE_MPEG_DASH: "dash",
    MEDIA_TYPE_DEFAULT: "auto",
    VALID_MEDIA_TYPES: ["auto", "mp4", "dynamic", "hls", "dash"],

    // Live or VOD stream?
    LIVE_MEDIA_DEFAULT: false,

    MEDIA_TYPE_EXTS: {
        "mp4": "mp4",
        "m3u8": "hls",
        "mpd": "dash",
        "ism": "dynamic",
        "isml": "dynamic"
    },
    MEDIA_TYPE_EXT_MP4: "mp4",
    MEDIA_TYPE_EXT_HLS: "m3u8",
    MEDIA_TYPE_EXT_MPEG_DASH: "mpd",
    VALID_MEDIA_TYPE_EXTS: ["mp4", "m3u8", "mpd", "ism", "isml"],

    // Playback defaults
    PLAYBACK_AUTOSTART_DEFAULT: false,

    // DRM-related defaults
    APPLICATION_ID_DEFAULT: "afl",
    CRM_ID_DEFAULT: "afl",
    ACCOUNT_ID_DEFAULT: "afl",
    KEY_ID_DEFAULT: '',

    // DRM profiles
    DRM_PROFILE_STAGING: "staging",
    DRM_PROFILE_PRODUCTION: "production",
    DRM_PROFILE_DEFAULT: "production",
    VALID_DRM_PROFILES: ["staging", "production"],

    DRM_VOD_CONTENT_ID_SUFFIX: "_ext",

    // Player environment setup
    DEPENDENCY_URL_BASE_DEFAULT: "//cdn.dstv.com/dstv-player/",
    DEPENDENCY_URL_BASE_LOCAL: '/assets/dstv-player/',
    ENV_LOCAL: 'local',
    ENV_QA: 'qa',
    ENV_STAGING: 'staging',
    ENV_PRODUCTION: 'production',
    ENV_URLS: {
        'qa': 'qa',
        'staging': 'staging',
        'production': 'prod'
    },
    VALID_ENV_TYPES: ['local', 'qa', 'staging', 'production'],
    // Default environments for different hosts
    ENV_HOSTS: {
        'localhost': 'local',
        '127.0.0.1': 'local',
        'demo.dstv.com': 'local',
        'player-qa.dstv.com': 'qa',
        'qa-player.dstv.com': 'qa',
        'player-staging.dstv.com': 'staging'
    },

    // DRM server config
    DRM_SERVERS: {
        "dstv": 'license.dstv.com',
        "supersport": "license.supersport.com"
    },

    DRM_SERVERS_URLS: {
        "staging": {
            "playready": "https://fra-dstv.stage.ott.irdeto.com/playready/rightsmanager.asmx?CrmId={{crmId}}&AccountId={{accountId}}&ContentId={{contentId}}",
            "widevine": "https://fra-dstv.stage.ott.irdeto.com/widevine/getLicense?CrmId={{crmId}}&AccountId={{accountId}}&ContentId={{contentId}}",
            "fairplay": {
                "url": "https://dstv.stage.ott.irdeto.com/streaming/getckc?CrmId={{crmId}}&AccountId={{accountId}}&ContentId={{contentId}}&KeyId={{keyId}}",
                "keyUrl": "https://dstv.stage.ott.irdeto.com/streaming/getcertificate?applicationId={{applicationId}}"
            }
        },
        "production": {
            "playready": "https://{{server}}/playready/rightsmanager.asmx?CrmId={{crmId}}&AccountId={{accountId}}&ContentId={{contentId}}",
            "widevine": "https://{{server}}/widevine/getLicense?CrmId={{crmId}}&AccountId={{accountId}}&ContentId={{contentId}}",
            "fairplay": {
                "url": "https://{{server}}/streaming/getckc?CrmId={{crmId}}&AccountId={{accountId}}&ContentId={{contentId}}&KeyId={{keyId}}",
                "keyUrl": "https://{{server}}/streaming/getcertificate?applicationId={{applicationId}}"
            }
        }
    },

    DRM_META_API_SERVERS: {
        "staging": "http://v.dstv.com/key-meta/",
        "production": "http://v.dstv.com/key-meta/"
    },

    // Players
    PLAYER_JWPLAYER: "jwplayer",
    PLAYER_FLASH: "flash",
    PLAYER_BITMOVIN: "bitmovin",
    PLAYER_BITMOVIN5: "bitmovin5",
    PLAYER_HTML5: "html5",
    PLAYER_DEFAULT: "bitmovin",
    VALID_PLAYERS: ["jwplayer", "flash", "bitmovin", "html5", "bitmovin5"],

    // Dependencies
    PLAYER_DEPENDENCIES: {
        "flash": {
            "swfObject": "//cdn.dstv.com/libs/swfobject/swfobject.min.js",
            "swf": "//core.dstv.com/video/flash/preloader.swf"
        },
        "jwplayer": {
            "jwplayer": "jwplayer/jwplayer.js",
            "skins": {
                "dstvnow": "jwplayer/css/skin-dstvnow.min.css",
                "boxoffice": "jwplayer/css/skin-boxoffice.min.css",
                "supersport": "jwplayer/css/skin-supersport.min.css",
                "freevod": "jwplayer/css/skin-freevod.min.css"
            }
        },
        "bitmovin": {
            "bitmovin": "https://bitmovin-a.akamaihd.net/bitmovin-player/stable/6/bitmovinplayer.min.js",
            "skins": {
                "dstvnow": "bitmovin/dstvnow.json",
                "boxoffice": "bitmovin/boxoffice.json",
                "supersport": "bitmovin/supersport.json",
                "freevod": "bitmovin/freevod.json"
            }
        },
        "bitmovin5": {
            "bitmovin": "https://bitmovin-a.akamaihd.net/bitmovin-player/stable/5/bitdash.min.js",
            "skins": {
                "dstvnow": "bitmovin/dstvnow.json",
                "boxoffice": "bitmovin/boxoffice.json",
                "supersport": "bitmovin/supersport.json",
                "freevod": "bitmovin/freevod.json"
            }
        },
        "html5": {}
    },

    // Player config
    PLAYER_WIDTH_DEFAULT: "100%",
    PLAYER_HEIGHT_DEFAULT: "100%",
    PLAYER_STRETCHING_DEFAULT: "uniform",
    PLAYER_ASPECT_RATIO_DEFAULT: "16:9",

    // JW Player-specific settings
    JWPLAYER_LICENSE_KEY: "FTqME7oJvTkKxfTpKLQMAJSZ0w0s5D5kcqSN/g==",
    JWPLAYER_ICONS_CSS: "jwplayer/fonts/css/dstv-icons.min.css",

    // Bitmovin-specific settings
    BITMOVIN_LICENSE_KEY: "bc730439-4903-486b-9f29-8340c7c91170",

    // Player skins
    SKIN_DSTVNOW: "dstvnow",
    SKIN_BOXOFFICE: "boxoffice",
    SKIN_SUPERSPORT: "supersport",
    SKIN_FREEVOD: "freevod",
    SKIN_DEFAULT: "dstvnow",
    VALID_SKINS: ["dstvnow", "boxoffice", "supersport", "freevod"],

    ANALYTICS_ADOBE_ENABLE : true,
    ANALYTICS_GRAYLOG_ENABLE : true,
    ANALYTICS_GRAYLOG_URL : "https://ssl.dstv.com/connect/sso/Scripts/AuthScript.ashx",

    // Force debug mode from the browser
    FORCE_DEBUG_HASH: "debug",

    // TODO: Refactor this into the MESSAGES object
    DRM_NO_CONTENT_ID_MESSAGE: "When playing encrypted content, one *must* specify a \"drm\" configuration parameter with a "+
    "\"contentId\" sub-parameter. Otherwise, if you are playing free/unencrypted content, please leave "+
    "the \"drm\" configuration parameter out completely when constructing the player.",

    DRM_NOT_SUPPORTED_EVENT: 'DRM-NOT-SUPPORTED',
    DRM_NOT_SUPPORTED_CLASS: 'drm-not-supported',
    DRM_NOT_SUPPORTED_MESSAGE: 'DRM is not supported.',

    LOGGING_DRM_TEST: 'DRM_TEST - ',

    PLAYER_ERROR_CLASS: 'player-error',

    EVENTS: {
        READY: 'onReady',
        PLAY: 'onPlay',
        PAUSE: 'onPause',
        BUFFER: 'onBuffer',
        COMPLETE: 'onComplete',
        ERROR: 'onError',
        SEEK: 'onSeek',
        FULLSCREEN: 'onFullscreen',
        DRM_NOT_AVAILABLE: 'onDrmNotAvailable'
    },

    FAQ_LINK_DSTVNOW: 'http://now.dstv.com/Home/Help#device-support-1',
    FAQ_LINK_BOXOFFICE: 'http://boxoffice.dstv.com/help#pcDeviceSupport',

    // one place to configure important messages (visible to the user)
    MESSAGES: {
        DRM_NOT_AVAILABLE: 'No content protection mechanism is available for your browser.',
        NO_DRM_AVAILABLE: 'Cannot play video as your browser or operating system is ',
        NO_ASSET_AVAILABLE: 'Sorry, we’re unable to play this video right now, please retry in a few minutes.',
        NO_NETWORK_AVAILABLE: 'We’re unable to play this video right now, please check your connection and try again.',
        PRIVATE_INCOGNITO_MODE: 'You cannot play this video in private or incognito mode.'
    }
};
