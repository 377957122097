/**
 * Our logic for handling the detection of DRM.
 */

var constants = require('../config/constants.js');

var domainsToCheck = [
    'com.widevine.alpha',
    'com.microsoft.playready',
    'com.apple.fairplay'
];
var domainsToCheckCount = domainsToCheck.length;

function evaluateSupport(systemsChecked, systemsSupported, successCallback, failedCallback, logger) {
    if(systemsChecked == domainsToCheckCount) {
        logger.log(constants.LOGGING_DRM_TEST + 'All domains checked');
        if (systemsSupported > 0) {
            logger.log(constants.LOGGING_DRM_TEST + '');
            if (successCallback) {
                successCallback();
            }
        } else {
            logger.error('No DRM support detected');
            if (failedCallback) {
                failedCallback();
            }
        }
    }
}

function testDrmSupport(successCallback, failedCallback, logger) {
    // EME Check
    logger.log(constants.LOGGING_DRM_TEST + 'domainsToCheckCount: ' + domainsToCheckCount);

    var testVideoElement = document.createElement('video');
    var systemsChecked = 0;
    var systemsSupported = 0;
    var domain;

    if (!testVideoElement.mediaKeys) {
        if (window.navigator.requestMediaKeySystemAccess) {
            if (typeof window.navigator.requestMediaKeySystemAccess === 'function') {
                logger.log(constants.LOGGING_DRM_TEST + 'found default EME');

                var isKeySystemSupported = function (keySystem) {
                    var config = [{initDataTypes: ['cenc']}];
                    if (window.navigator.requestMediaKeySystemAccess) {
                        window.navigator.requestMediaKeySystemAccess(keySystem, config).then(function (keySystemAccess) {
                            logger.log(constants.LOGGING_DRM_TEST + keySystem + ' support = YES');
                            systemsChecked++;
                            systemsSupported++;
                            evaluateSupport(systemsChecked, systemsSupported, successCallback, failedCallback, logger);
                        }).catch(function () {
                            logger.log(constants.LOGGING_DRM_TEST + keySystem + ' support = NO');
                            systemsChecked++;
                            evaluateSupport(systemsChecked, systemsSupported, successCallback, failedCallback, logger);
                        });
                    }
                };

                for (domain in domainsToCheck) {
                    if (domainsToCheck.hasOwnProperty(domain)) {
                        isKeySystemSupported(domainsToCheck[domain]);
                    }
                }
            }
        }
        else if (window.MSMediaKeys) {
            if (typeof window.MSMediaKeys === 'function') {
                logger.log(constants.LOGGING_DRM_TEST + 'found MS-EME');

                for (domain in domainsToCheck) {
                    if (domainsToCheck.hasOwnProperty(domain)) {
                        if (MSMediaKeys.isTypeSupported(domainsToCheck[domain])) {
                            logger.log(constants.LOGGING_DRM_TEST + domainsToCheck[domain] + ' support = YES');
                            systemsSupported++;
                        } else {
                            logger.log(constants.LOGGING_DRM_TEST + domainsToCheck[domain] + ' support = NO');
                        }
                        systemsChecked++;
                    }
                }

                evaluateSupport(systemsChecked, systemsSupported, successCallback, failedCallback, logger);
            }
        } else if (testVideoElement.webkitGenerateKeyRequest) {
            if (typeof testVideoElement.webkitGenerateKeyRequest === 'function') {
                logger.log(constants.LOGGING_DRM_TEST + 'found WebKit EME');
                systemsSupported++;
            }
            evaluateSupport(domainsToCheckCount, systemsSupported, successCallback, failedCallback, logger);
        } else {
            logger.log(constants.LOGGING_DRM_TEST + 'no supported EME implementation found');
            // no systems supported
            evaluateSupport(domainsToCheckCount, 0, successCallback, failedCallback, logger);
        }
    }
}

module.exports = {
    testDrmSupport: testDrmSupport
};
