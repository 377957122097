/**
 * Hack to temporarily bypass Require.js while loading our player. At the time of this development,
 * there's a bug in JW Player that causes Require.js to crash our loading process if Require.js is
 * included prior to including JW Player (as it is on the DStv Now web site).
 */

var originalRequireJS = null;
var originalRequire = null;
var originalDefine = null;
var requireJsDeps = [];
var defineDeps = [];
var requireDeps = [];

function installFakeRequireJS(logger) {
    if (window.hasOwnProperty('requirejs') || window.hasOwnProperty('define') || window.hasOwnProperty('require')) {
        originalRequireJS = window.requirejs;
        originalRequire = window.require;
        originalDefine = window.define;
        window.requirejs = fakeRequireJS;
        window.define = fakeDefine;
        window.require = fakeRequire;
        logger.log("~ installFakeRequireJS:");
    }
}

function fakeRequireJS(deps, callback, errback, optional) {
    requireJsDeps.push({
        deps: deps,
        callback: callback,
        errback: errback,
        optional: optional
    });
}

// Actually the same as requirejs
function fakeRequire(deps, callback, errback, optional) {
    requireDeps.push({
        deps: deps,
        callback: callback,
        errback: errback,
        optional: optional
    });
}

function fakeDefine(name, deps, callback) {
    defineDeps.push({
        name: name,
        deps: deps,
        callback: callback
    });
}

function restoreRequireJS(logger) {
    logger.log("~ restoreRequireJS - originalRequireJS: " + originalRequireJS + ", originalDefine: " + originalDefine);
    if (originalRequireJS || originalDefine) {
        var i, params;

        window.requirejs = originalRequireJS;
        window.require = originalRequire;
        window.define = originalDefine;

        for (i = 0; i < defineDeps.length; i++) {
            params = defineDeps[i];
            window.define(params.name, params.deps, params.callback);
        }
        for (i = 0; i < requireJsDeps.length; i++) {
            params = requireJsDeps[i];
            window.requirejs(params.deps, params.callback, params.errback, params.optional);
        }
        for (i = 0; i < requireDeps.length; i++) {
            params = requireDeps[i];
            window.require(params.deps, params.callback, params.errback, params.optional);
        }
    }
}

module.exports = {
    installFakeRequireJS: installFakeRequireJS,
    restoreRequireJS: restoreRequireJS
};
