/**
 * Module to encapsulate the old DStv Flash-based player.
 */

var $ = require('jquery');
var asyncRequire = require('../../utils/async-require.js');
var constants = require('../../config/constants.js');
var config = require('../../config/config.js');
var utils = require('../../utils/misc.js');

/**
 * Initialises the DStv Flash-based player.
 * @param {string} el The ID of the target element into which to inject the Flash player instance.
 * @param {object} options The configuration options for the player.
 * @param {object} logger Our logging functions.
 */
function initFlashPlayer(el, options, logger) {

    logger.log('Loading swfobject from: '+options.dependencies.swfObject);
    asyncRequire(
        options.dependencies.swfObject,
        function() { onSwfObjectLoaded(el, options, logger); },
        logger
    );

    $(window).resize(function() {
        $('#'+el).css("height", getPlayerHeight(el, options.aspectRatio));
    });
}

function getPlayerWidth(el) {
    return $('#'+el).css('width').replace('px','');
}

function getPlayerHeight(el, ratio) {
    return utils.heightFromAspectRatio(getPlayerWidth(el), ratio);
}

function onSwfObjectLoaded(el, options, logger) {
    var width = config.getPlayerOpt(options, 'width', constants.PLAYER_WIDTH_DEFAULT);
    var height = config.getPlayerOpt(options, 'height', constants.PLAYER_HEIGHT_DEFAULT);

    logger.log('// Dimensions: ' + width + ' x ' + height);

    height = getPlayerHeight(el, options.aspectRatio);

    var flashvars = {
        feedUrl: options.feed + '?'
    };
    var params = {
        scale: "exactFit",
        menu: config.getPlayerOpt(options, 'menu', 'false'),
        bgcolor: config.getPlayerOpt(options, 'bgcolor', '#000000'),
        allowFullscreen: config.getPlayerOpt(options, 'allowFullscreen', 'true'),
        allowScriptAccess: config.getPlayerOpt(options, 'allowScriptAccess', 'always'),
        // wmode: config.getPlayerOpt(options, 'wmode', 'transparent'),
    };
    var attributes = {
        id: el
    };
    logger.log('Flash player configuration:', {
        flashvars: flashvars,
        params: params,
        attributes: attributes
    });
    swfobject.embedSWF(
        options.dependencies.swf,
        el,
        width,
        height,
        '10.2',
        'http://core.dstv.com/flash/expressInstall.swf',
        flashvars,
        params,
        attributes,
        logger.log
    );
}

function destroyFlashPlayer(el, logger) {
    logger.log('Destroying Flash Player');
    $('#'+el).empty();
}

module.exports = {
    init: initFlashPlayer,
    destroy: destroyFlashPlayer
};
