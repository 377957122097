/**
 * Configuration loading from the data feeds.
 */

var $ = require('jquery');
var constants = require('./constants.js');

var FEED_TYPE_PARSERS = {};
FEED_TYPE_PARSERS[constants.FEED_TYPE_JSON_SIMPLE] = require('../feeds/parsers/json-simple.js');

/**
 * Attempts to build up as many options as possible from the feed.
 * @param {object} options The player configuration options.
 * @param {object} feedConfig The feed config.
 * @param {function} success The callback to call on success (takes one parameter: the updated configuration object).
 * @param {object} logger Our logging configuration.
 */
function configurePlayerFromFeed(options, feedConfig, success, logger) {
    $.ajax({
        url: feedConfig.url,
        method: 'GET',
        dataType: 'json'
    }).done(function(data) {
        success(FEED_TYPE_PARSERS[feedConfig.type](options, data, logger));
    }).fail(function(xhr) {
        logger.error(xhr);
    });
}


module.exports = {
    configurePlayerFromFeed: configurePlayerFromFeed
};
