/**
 * Constants to be used within tracking.
 */

module.exports = {

    // Graylog tracking events
    PLAY              : 'Play',
    PAUSE             : 'Pause',
    UNPAUSE           : 'Unpause',
    BUFFER            : 'Buffer',
    IDLE              : 'Idle',
    COMPLETE          : 'Complete',
    SEEK              : 'Seek',
    TIME              : 'Time',
    FULLSCREEN        : 'Fullscreen',
    FULLSCREEN_EXIT   : 'Fullscreen Exit',

    TIME_PLAYED       : 'Time Played',
    REWIND            : 'Rewind',
    REPLAY            : 'Replay',
    SHARE             : 'Share',

    MILESTONE25       : 'Milestone 25',
    MILESTONE50       : 'Milestone 50',
    MILESTONE75       : 'Milestone 75',
    MILESTONE90       : 'Milestone 90',

    READY             : 'Ready',
    SETUP_ERROR       : 'Setup Error',
    ERROR             : 'Error',
    META              : 'Meta',

    LEVELS            : 'Levels',
    LEVELSCHANGED     : 'Levels Changed'
};