/**
 * Custom handling for FairPlay license acquisition. This is a workaround
 * for an issue in JW Player (as of 17 August 2016).
 */

var $ = require('jquery');
var utils = require('./misc.js');


/**
 * Attaches the FairPlay license acquisition event mechanisms to the relevant target.
 * @param {string} target The ID of the element into which the player is going to be injected.
 * @param {string} certUrl The full URL as to where to fetch the certificate for FairPlay licensing.
 * @param {string} laUrl The full license acquisition URL.
 * @param {object} logger Our logging configuration from the player.
 */
function attachFairPlayLicensor(target, certUrl, laUrl, logger) {

    logger.log('Attempting to fetch FairPlay certificate from: '+certUrl);
    var request = new XMLHttpRequest();
    request.responseType = 'arraybuffer';
    request.addEventListener(
        'load',
        function() { onCertificateLoaded(target, laUrl, logger); },
        false
    );
    request.addEventListener(
        'error',
        function(error) { onCertificateError(error, logger); },
        false
    );
    request.open('GET', certUrl, true);
    request.send();

}


function onCertificateLoaded(target, laUrl, logger) {
    var request = event.target;
    var certificate = new Uint8Array(request.response);

    logger.log('Certificate loaded. Binding event for when WebKit needs DRM keys...');
    bindNeedKeyEvent(target, laUrl, certificate, logger);
}


function onCertificateError(event, logger) {
    logger.error('Failed to retrieve the server certificate', event);
}


function bindNeedKeyEvent(target, laUrl, certificate, logger) {
    var video = $('#'+target+' video').get(0);
    logger.log('Got video tag object: '+video);

    video.addEventListener(
        'webkitneedkey',
        function(event) { onNeedKey(event, laUrl, certificate, logger); },
        false
    );
    video.addEventListener(
        'error',
        function(event) { onNeedKeyError(event, logger); },
        false
    );
}


function onNeedKey(event, laUrl, certificate, logger) {
    logger.log('onNeedKey()');
    var video = event.target;
    var initData = event.initData;
    var contentId = extractContentId(initData, logger);
    initData = concatInitDataIdAndCertificate(initData, contentId, certificate);

    if (!video.webkitKeys) {
        video.webkitSetMediaKeys(new WebKitMediaKeys(selectKeySystem()));
    }
    if (!video.webkitKeys) {
        throw "Could not create MediaKeys";
    }

    var keySession = video.webkitKeys.createSession("video/mp4", initData);
    if (!keySession) {
        throw "Could not create key session";
    }

    keySession.contentId = contentId;
    waitForEvent(
        'webkitkeymessage',
        function(event) { onLicenseRequestReady(event, laUrl, logger); },
        keySession
    );
    waitForEvent(
        'webkitkeyadded',
        function(event) { onKeyAdded(event, logger); },
        keySession
    );
    waitForEvent(
        'webkitkeyerror',
        function(event) { onKeyError(event, logger); },
        keySession
    );
}


function onNeedKeyError(event, logger) {
    logger.error('A video playback error occurred', event);
}


function extractContentId(initData, logger) {
    /*var contentId = '//' + utils.arrayToString(initData).split('://', 2)[1];
    // contentId is passed up as a URI, from which the host must be extracted:
    var link = document.createElement('a');
    link.href = contentId;
    logger.log('Extracted contentId: ' + link.href);
    return link.hostname;*/
    var contentId = utils.arrayToString(initData).match(/ContentId=([^&]+)/)[1];
    logger.log('Extracted contentId: ' + contentId);
    return contentId;
}


function concatInitDataIdAndCertificate(initData, id, cert) {
    if (typeof id == "string") {
        id = utils.stringToArray(id);
    }
    // layout is [initData][4 byte: idLength][idLength byte: id][4 byte:certLength][certLength byte: cert]
    var offset = 0;
    var buffer = new ArrayBuffer(initData.byteLength + 4 + id.byteLength + 4 + cert.byteLength);
    var dataView = new DataView(buffer);
    var initDataArray = new Uint8Array(buffer, offset, initData.byteLength);
    initDataArray.set(initData);
    offset += initData.byteLength;

    dataView.setUint32(offset, id.byteLength, true);
    offset += 4;

    var idArray = new Uint16Array(buffer, offset, id.length);
    idArray.set(id);
    offset += idArray.byteLength;

    dataView.setUint32(offset, cert.byteLength, true);
    offset += 4;

    var certArray = new Uint8Array(buffer, offset, cert.byteLength);
    certArray.set(cert);

    return new Uint8Array(buffer, 0, buffer.byteLength);
}


function selectKeySystem() {
    if (WebKitMediaKeys.isTypeSupported("com.apple.fps.1_0", "video/mp4")) {
        return "com.apple.fps.1_0";
    } else {
        throw "Key System not supported";
    }
}


function waitForEvent(name, action, target) {
    target.addEventListener(name, function () {
        action(arguments[0]);
    }, false);
}


function onLicenseRequestReady(event, laUrl, logger) {
    logger.log('License request ready');
    var session = event.target;
    var message = event.message;
    var request = new XMLHttpRequest();
    request.responseType = 'arraybuffer';
    request.session = session;
    request.addEventListener(
        'load',
        function(event) { onLicenseRequestLoaded(event, logger); },
        false
    );
    request.addEventListener(
        'error',
        function(event) { onLicenseRequestFailed(event, logger); },
        false
    );
    request.open('POST', laUrl, true);
    request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

    logger.log('Sending POST request for license to: ' + laUrl);
    request.send(message);
}


function onLicenseRequestLoaded(event, logger) {
    logger.log('License request loaded');
    var request = event.target;
    var session = request.session;
    var key = new Uint8Array(request.response);
    session.update(key);
}


function onLicenseRequestFailed(event, logger) {
    logger.error('The license request failed', event);
}


function onKeyError(event, logger) {
    logger.error('A decryption key error was encountered', event);
}


function onKeyAdded(event, logger) {
    logger.log('Decryption key was added to session', event);
}


module.exports = attachFairPlayLicensor;
