/**
 * Adobe implementation class for tracking.
 */

var adobe = require('./events-adobe.js');
var constants = require('../config/constants.js');
var utils = require('../utils/misc.js');

var adobeData;
var isAdobeTracking = constants.ANALYTICS_ADOBE_ENABLE;

function setupData(object, logger) {
    if (isAdobeTracking == false || typeof _satellite == 'undefined') {
        logger.log('// Analytics disabled: Adobe DTM, script not available');
    } else if (isAdobeObjectAvailable() == false) {
        logger.log('// Analytics adobe object not available');
    } else {
        adobeData = {};
        // Create properties that have values
        if (object.base36ID) adobeData.base36ID = object.base36ID;
        if (object.contentId) adobeData.videoID = object.contentId;

        if (object.title) adobeData.videoTitle = object.title;
        if (object.season) adobeData.videoSeason = object.season;
        if (object.episode) adobeData.videoEpisode = object.episode;
        if (object.channelName) adobeData.channelName = object.channelName;
        if (object.channelNumber) adobeData.channelNumber = object.channelNumber;
        if (object.category) adobeData.category = object.category;
        if (object.genre) adobeData.genre = object.genre;
        if (object.subGenre) adobeData.subGenre = object.subGenre;
        if (object.videoType) adobeData.videoType = object.videoType;
        if (object.rating) adobeData.rating = object.rating;

        if (object.playerName) adobeData.playerName = object.playerName;
        if (object.playerVersion) adobeData.playerVersion = object.playerVersion;
        if (object.product) adobeData.product = object.product;

        mergeWithAdobeObject(adobeData);
    }
}

function track(action) {
    if (isAdobeTracking && isAdobeObjectAvailable()) {
        _satellite.track(action);
    }
}

function error(message) {
    if (isAdobeTracking && isAdobeObjectAvailable()) {
        adobeData.videoErrorMessage = message;
        mergeWithAdobeObject(adobeData);
        _satellite.track(adobe.ERROR);
    }
}

function mergeWithAdobeObject(obj) {
    var adobeObject;
    if (typeof dstvNowDataLayer !== 'undefined') adobeObject = dstvNowDataLayer;
    else if (typeof dstvDataLayer !== 'undefined') adobeObject = dstvDataLayer;

    if (typeof adobeObject !== 'undefined') {
        adobeObject = utils.mergeObjects(adobeObject, obj);
        if (typeof dstvNowDataLayer != 'undefined') {
            dstvNowDataLayer = adobeObject;
            console.log('// dstvNowDataLayer:',dstvNowDataLayer);
        } else if (typeof dstvDataLayer != 'undefined') {
            dstvDataLayer = adobeObject;
            console.log('// dstvDataLayer:',dstvDataLayer);
        }
    }
}

function isAdobeObjectAvailable() {
    if (typeof playerDataLayer !== 'undefined') return true;
    else if (typeof dstvNowDataLayer !== 'undefined') return true;
    else if (typeof dstvDataLayer !== 'undefined') return true;
    return false;
}

module.exports = {
    setupData: setupData,
    track: track,
    error: error
};