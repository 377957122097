/**
 * Simple HTML5 player based on Shaka (for MPEG DASH) and the standard Safari HTML5 HLS playback mechanisms
 * for playing media.
 */

var $ = require('jquery');
var bowser = require('bowser');
var shaka = require('shaka-player');

var config = require('../../config/config.js');
var constants = require('../../config/constants.js');
var utils = require('../../utils/misc.js');
var attachFairPlayLicensor = require('../../utils/fairplay-licensor.js');


function initHtml5Player(el, options, logger) {
    // ensure we have the correct URL for the source
    options.url = config.constructSourceUrl(options, logger);

    if (bowser.safari) {
        logger.log('Initialising HTML5 player for Safari');
        initSafariHtml5Player(el, options, logger);
    } else {
        if (options.mediaType == constants.MEDIA_TYPE_MPEG_DASH || options.mediaType == constants.MEDIA_TYPE_DYNAMIC) {
            logger.log('Initialising MPEG DASH player');
            initMpegDashPlayer(el, options, logger);
        } else {
            logger.log('Initialising generic HTML5 player');
            initGenericHtml5Player(el, options, logger);
        }
    }
}


/**
 * Resizes the video player in the given container element.
 * @param {string} el The ID of the container element in which to find the video tag.
 * @param {number|string} width The desired width of the video element.
 * @param {number|string|void} height The desired height of the video element (takes precedence over aspect ratio).
 * @param {string|void} aspectRatio The desired aspect ratio of the video element if no width has been specified.
 * @param {object} logger Our logging configuration.
 */
function resizePlayer(el, width, height, aspectRatio, logger) {
    var video = $('#'+el+' video');
    video.width(width);
    if (height) {
        video.height(height);
    } else {
        video.height(utils.heightFromAspectRatio(video.width(), aspectRatio));
    }
}


function doResizePlayer(el, options, logger) {
    resizePlayer(
        el,
        options.width,
        utils.getOrDefault(options, 'height', null),
        utils.getOrDefault(options, 'aspectRatio', null),
        logger
    );
}


function setPlayerResizeEvent(el, options, logger) {
    $(window).resize(function() {
        doResizePlayer(el, options, logger);
    });
}


/**
 * Common player initialisation functionality (performs the actual video tag injection).
 * @param {string} el The ID of the element into which to inject the player.
 * @param {object} options The configuration options for our player.
 * @param {object} logger Our logging configuration.
 * @param {boolean} noSource Should we leave the source tag out when compiling the video tag?
 */
function initPlayerCommon(el, options, logger, noSource) {
    var posterUrl = utils.getOrDefault(options, 'image', config.getPlayerOpt(options, 'poster', null));
    var preload = "none";
    if ((bowser.safari && options.mediaType == constants.MEDIA_TYPE_HLS) ||
        (options.mediaType == constants.MEDIA_TYPE_MP4)) {
        preload = "metadata";
    }
    if (!noSource) {
        noSource = false;
    }

    $('#'+el).html(
        '<video controls="controls" ' +
            (options.autoStart ? 'autoplay="autoplay" ' : '') +
            'width="'+utils.escapeHtmlAttr(options.width)+'" ' +
            (posterUrl ? 'poster="'+utils.escapeHtmlAttr(posterUrl)+'" ' : '') +
            'preload="'+preload+'">' +
            (noSource ? '' : '<source src="'+utils.escapeHtmlAttr(options.url)+'" />')+
        '</video>'
    );
    // trigger a resize right now
    doResizePlayer(el, options, logger);
    // make sure it resizes when the window resizes
    setPlayerResizeEvent(el, options, logger);
}


function initSafariHtml5Player(el, options, logger) {
    initPlayerCommon(el, options, logger);
    if (options.drm) {
        var drmConfig = constructDrmConfig(options);
        // install our FairPlay license acquisition code
        attachFairPlayLicensor(el, drmConfig.fairplay.keyUrl, drmConfig.fairplay.url, logger);
    }
}


function onShakaPlayerError(e, logger) {
    logger.error('Shaka Player error', e);
}


function initShakaPlayer(el, options, logger) {
    var video = $('#'+el+' video')[0];
    var player = new shaka.Player(video);

    player.addEventListener('error', function(e) {
        onShakaPlayerError(e, logger);
    });

    if (options.drm) {
        var drmConfig = constructDrmConfig(options);

        player.configure({
            drm: {
                servers: {
                    'com.widevine.alpha': drmConfig.widevine.url,
                    'com.microsoft.playready': drmConfig.playready.url
                }
            }
        });
    }

    player.load(options.url).then(function() {
        logger.log('Shaka player loaded');
    }).catch(function(e) {
        onShakaPlayerError(e, logger);
    });
}


function initMpegDashPlayer(el, options, logger) {
    // create the player
    initPlayerCommon(el, options, logger, true);

    // initialise Shaka player
    shaka.polyfill.installAll();

    if (shaka.Player.isBrowserSupported()) {
        initShakaPlayer(el, options, logger);
    } else {
        logger.error('Shaka player does not support this browser');
    }
}


/**
 * Initialises a simple generic HTML5 player (generally useful for playing MP4 files).
 * @param {string} el The ID of the element into which to inject the player.
 * @param {object} options The configuration options for our player.
 * @param {object} logger Our logging configuration.
 */
function initGenericHtml5Player(el, options, logger) {
    initPlayerCommon(el, options, logger);
}


function constructDrmConfig(options) {
    var servers = constants.DRM_SERVERS_URLS[options.drm.profile];
    return {
        widevine: {
            url: utils.quickTemplateRender(servers.widevine, options.drm) + options.drm.manCookie
        },
        playready: {
            url: utils.quickTemplateRender(servers.playready, options.drm) + options.drm.manCookie
        },
        fairplay: {
            url: utils.quickTemplateRender(servers.fairplay.url, options.drm) + options.drm.manCookie,
            keyUrl: utils.quickTemplateRender(servers.fairplay.keyUrl, options.drm)
        }
    };
}

function destroyHtml5Player(el, logger) {
    logger.log('Destroying HTML5 player');
    $('#'+el).empty();
}


module.exports = {
    init: initHtml5Player,
    destroy: destroyHtml5Player
};
