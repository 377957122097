/**
 * Helper function for negotiating key ID from DRM Key Meta API.
 */

var constants = require('../config/constants.js');

/**
 * Attempts to fetch the DRM key ID from the DRM Key Meta API.
 * @param options The configuration options.
 * @param success The function to call when ultimately successful.
 * @param logger Our logging configuration.
 */
function fetchDrmKeyId(options, success, logger) {
    var apiUrl = constants.DRM_META_API_SERVERS[options.drm.profile]+options.drm.contentId;
    logger.log('Attempting to fetch key ID from DRM Meta API: '+apiUrl);
    $.ajax({
        url: apiUrl,
        method: 'GET',
        dataType: 'json',
        success: function(data) {
            logger.log('Received response from DRM Meta API: ', data);
            if (!data.hasOwnProperty('keyId')) {
                logger.error('Missing key ID in response from DRM Meta API');
            } else {
                options.drm.keyId = data.keyId;
                // call our success callback
                success(options, logger);
            }
        },
        error: function(xhr) {
            // log the error
            logger.error('Error while attempting to fetch key for content ID: '+options.drm.contentId+' - '+
                xhr.responseText+' ('+xhr.status+')', xhr);
        }
    });
}

module.exports = fetchDrmKeyId;
