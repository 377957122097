/**
 * Tracking implementation class.
 */

var event = require('./events-graylog.js');
var eventAdobe = require('./events-adobe.js');
var adobe = require('./adobe.js');
var graylog = require('./graylog.js');

var n;
var isPaused;
var isTracking = false;
var isLive = false;

function init(object, live, logger) {
    if (typeof object != 'undefined') {
        // Setup metadata objects for tracking
        n = 0;
        isPaused = false;
        isTracking = true;
        isLive = live;

        adobe.setupData(object, logger);
        graylog.setupData(object, logger);
    } else {
        isTracking = false;
        logger.log('Analytics object undefined');
    }
}

function track(evt, logger, e) {
    if (isTracking) {
        // logger.log("// event: " + evt);

        switch (evt) {
            case event.PLAY:
                if (0 === n || 100 === n) {
                    n = 1;
                    trackEvents(event.PLAY, logger);
                } else if (n > 0 && isPaused) {
                    trackEvents(event.UNPAUSE, logger);
                }
                break;
            case event.TIME:
                if (isLive == false) {
                    var percent = 100 * e.position / e.duration;
                    if (percent >= 25 && 25 > n) {
                        n = 25;
                        trackEvents(event.MILESTONE25, logger);
                    } else if (percent >= 50 && 50 > n) {
                        n = 50;
                        trackEvents(event.MILESTONE50, logger);
                    } else if (percent >= 75 && 75 > n) {
                        n = 75;
                        trackEvents(event.MILESTONE75, logger);
                    } else if (percent >= 90 && 90 > n) {
                        n = 90;
                        trackEvents(event.MILESTONE90, logger);
                    } else if (percent >= 100 && 100 > n) {
                        n = 100;
                    }
                }
                break;
            case event.FULLSCREEN:
                if (e.fullscreen === true) {
                    trackEvents(event.FULLSCREEN, logger);
                } else {
                    trackEvents(event.FULLSCREEN_EXIT, logger);
                }
                break;
            default:
                trackEvents(evt, logger);
                break;
        }
    }
}

function error(message, logger){
    if (isTracking) {
        logger.log("// error: " + message);

        adobe.error(message);
        graylog.error(message);
    }
}

function trackEvents(evt, logger) {
    logger.log("// trackEvent: " + evt);

    graylog.track(evt);

    switch (evt) {
        case event.PLAY:
            isPaused = false;
            adobe.track(eventAdobe.PLAY);
            break;
        case event.PAUSE:
            isPaused = true;
            adobe.track(eventAdobe.PAUSE);
            break;
        case event.UNPAUSE:
            isPaused = false;
            adobe.track(eventAdobe.UNPAUSE);
            break;
        case event.BUFFER:
            adobe.track(eventAdobe.STOP);
            break;
        case event.IDLE:
            adobe.track(eventAdobe.STOP);
            break;
        case event.COMPLETE:
            adobe.track(eventAdobe.COMPLETE);
            break;
        case event.SEEK:
            adobe.track(eventAdobe.SEEK);
            break;
        case event.MILESTONE25:
            adobe.track(eventAdobe.MILESTONE25);
            break;
        case event.MILESTONE50:
            adobe.track(eventAdobe.MILESTONE50);
            break;
        case event.MILESTONE75:
            adobe.track(eventAdobe.MILESTONE75);
            break;
        case event.MILESTONE90:
            adobe.track(eventAdobe.MILESTONE90);
            break;
        case event.FULLSCREEN:
            adobe.track(eventAdobe.FULLSCREEN);
            break;
        case event.FULLSCREEN_EXIT:
            adobe.track(eventAdobe.FULLSCREEN_EXIT);
            break;
        default:
            break;
    }
}

module.exports = {
    init: init,
    track: track,
    error: error
};