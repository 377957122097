/**
 * Primary entrypoint for the DStv HTML5 video player (with support for multiple different DRM
 * schemes). At present, the following DRM schemes are supported:
 * - PlayReady (IE 11/Edge)
 * - Widevine (Chrome/Firefox)
 * - FairPlay (Safari)
 */

var $ = require('jquery');
var bowser = require('bowser');
var config = require('./config/config.js');
var feedConfig = require('./config/feed-config.js');
var constants = require('./config/constants.js');
var logging = require('./utils/logging.js');
var utils = require('./utils/misc.js');
var renderHtml = require('./utils/render-html.js');
var manCookie = require('./utils/man-cookie.js');
var detectDrm = require('./utils/detect-drm.js');
var fetchDrmKeyId = require('./utils/key-meta-api.js');
var detectPrivateMode = require('./utils/detect-private-browsing.js');

var PLAYERS = {};
PLAYERS[constants.PLAYER_JWPLAYER] = require('./players/jwplayer/player.js');
PLAYERS[constants.PLAYER_BITMOVIN] = require('./players/bitmovin/player.js');
PLAYERS[constants.PLAYER_BITMOVIN5] = require('./players/bitmovin5/player.js');
PLAYERS[constants.PLAYER_FLASH] = require('./players/flash/player.js');
PLAYERS[constants.PLAYER_HTML5] = require('./players/html5/player.js');

var player = {
    el: null,
    loaded: false,
    id: null,
    options: null,
    isPlaying: returnFalse,
    isPaused: returnFalse
};

window.dstvMultiDrmPlayerApi = {};

/**
 * Primary entrypoint for instantiating the DStv Multi-DRM HTML5 player.
 * @param {string} el The ID of the element into which to inject the player.
 * @param {object} options The configuration options for the player.
 */
function initDstvMultiDrmPlayer(el, options) {
    options = config.checkDebugConfig(options);
    var logger = logging.configureLogging(options.debug);

    // hostname required for drm server
    options.hostname = window.location.hostname.toLowerCase();

    // check if the player's already been loaded once before
    destroyPreviousPlayer(logger);

    if(options.hasOwnProperty("drm") && options.drm) {
        // pass in callback function to continue player setup once promise resolves.
        detectDrm.testDrmSupport(function () {
            detectPrivateMode.detect(function (isPrivate) {
                if (isPrivate) {
                    renderHtml.message(el, constants.MESSAGES.PRIVATE_INCOGNITO_MODE);
                } else {
                    initFeedConfiguration(el, options, logger);
                }
            });
        }, function() {
            renderHtml.messageForHostname(el, constants.MESSAGES.NO_DRM_AVAILABLE, options.hostname);
            utils.triggerEvent(options.events, constants.EVENTS.DRM_NOT_AVAILABLE, null, null);
        }, logger);
    } else {
        initFeedConfiguration(el, options, logger);
    }
}

function initFeedConfiguration(el, options, logger) {
    // if we're supposed to use the feed
    if (options.hasOwnProperty('feed')) {
        feedConfig.configurePlayerFromFeed(
            options,
            config.validateFeedConfig(options),
            function(_options) {
                logger.log('Configuration object just after feed parsing:', _options);
                // configure our player with the new options
                initPlayerConfiguration(el, _options, logger);
            },
            logger
        );
    } else {
        initPlayerConfiguration(el, options, logger);
    }
}

function initPlayerConfiguration(el, options, logger) {
    logger.log('Instantiating Player Configuration:', options);

    // TODO: Take this out once Bitmovin fixes v6
    options = revertToBitmovin5ForMicrosoft(options, logger);

    // validate and prepopulate unpopulated configuration options
    options = config.validate(options);

    // if it's a DRM'd VOD asset and we're using Safari, but no key ID
    if (options.drm) {
        if (bowser.safari && !options.live && (!options.drm.hasOwnProperty('keyId') || !options.drm.keyId)) {
            logger.log('Attempting to fetch non-existent key ID for Safari');
            fetchDrmKeyId(options, function(_options, _logger) {
                initManCookieCheck(el, _options, _logger);
            }, logger);
        } else {
            initManCookieCheck(el, options, logger);
        }
    } else {
        initSelectedPlayer(el, options, logger);
    }
}

function initManCookieCheck(el, options, logger) {
    logger.log('Instantiating MAN Cookie Check');

    initSelectedPlayer(el, options, logger);

    manCookie.setupManCookieChangeChecks(options, logger, function () {
        tryResetPlayer(el, options, logger);
    });
}

function initSelectedPlayer(el, options, logger) {
    logger.log('~ Instantiating DStv Player with Configuration Object:', options);
    var currentPlayer = PLAYERS[options.player];
    currentPlayer.init(el, options, logger);
    player = {
        el: el,
        loaded: true,
        id: options.player,
        options: options,
        isPlaying: currentPlayer.isPlaying,
        isPaused: currentPlayer.isPaused
    };

    window.dstvMultiDrmPlayerApi.destroyPlayer = function () {
        destroyPreviousPlayer(logger);
        return true;
    };
}

function tryResetPlayer(el, options, logger) {
    logger.log('Try Reset DStv Player:', player);

    // only reset player if it is not currently playing
    if (player && !player.isPlaying() && !player.isPaused()) {
        destroyPreviousPlayer(logger);
        initFeedConfiguration(el, options, logger);
    }
}

function destroyPreviousPlayer(logger) {
    // check if the player's been loaded previously
    if (player.loaded) {
        logger.log('Player has already been loaded. Destroying existing player.');
        PLAYERS[player.id].destroy(player.el, player.options, logger);
        player = {
            el: null,
            loaded: false,
            id: null,
            options: null,
            isPlaying: returnFalse,
            isPaused: returnFalse
        };
    }
}

// TODO: Take this out once Bitmovin fixes v6
function revertToBitmovin5ForMicrosoft(options, logger) {
    if (bowser.msie || bowser.msedge) {
        if ((!options.hasOwnProperty('player') && constants.PLAYER_DEFAULT == constants.PLAYER_BITMOVIN) ||
            (options.player == constants.PLAYER_BITMOVIN)) {
            logger.log('Switching player to Bitmovin v5 for IE/Edge');
            options.player = constants.PLAYER_BITMOVIN5;
        }
    }
    return options;
}

function returnFalse() {
    return false;
}

// expose the initialisation routine globally
window.initDstvMultiDrmPlayer = initDstvMultiDrmPlayer;

// utility functions to expose globally
window.extractQueryParams = utils.extractQueryParams;
