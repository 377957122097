/**
 * Helper function for MAN Cookie.
 */

var cookies = require('js-cookie');
var utils = require('./misc.js');
var constants = require('../config/constants.js');

var timeStart = 0;

/**
 * Setup MAN Cookie change check options.
 * @param options - The player configuration options.
 * @returns player configuration options.
 */

function populateManCookie(options) {
    options.drm.manCookie = getManCookieString();
    return options;
}

function setupManCookieChangeChecks(options, logger, onChangeCallback) {
    if (options.drm && !options.live) {
        logger.log('DRM object. Will check for Man cookie changes.');

        timeStart = Date.now();

        var refreshIntervalId = setInterval(function () {
            logger.log('Checking MAN cookie');

            if (hasManCookieChange(options, logger)) {
                logger.log('MAN cookie has changed');
                if (onChangeCallback) {
                    onChangeCallback();
                }
            }
        }, constants.MAN_COOKIE_DURATION_IN_SECONDS * 1000);

        options.drm.clearManCookieInterval = function () {
            clearInterval(refreshIntervalId);
        };
    }
}

function hasManCookieChange(options, logger) {
    var hasChanged = false;
    var currentManCookieString = getManCookieString();

    if (!currentManCookieString) {
        logger.log('MAN cookie not found.');
        clearManCookieInterval(options, logger);
    }
    else if (currentManCookieString !== options.drm.manCookie) {
        populateManCookie(options);
        hasChanged = true;
    }
    return hasChanged;
}

function getManCookieString() {
    var cookieObject = cookies.get('MAN');

    if (cookieObject && typeof cookieObject === 'string') {
        var manCookieObject = utils.extractQueryParamsFromString(cookieObject);

        var manCookieString = "&SessionId=" + manCookieObject.SessionId;
        manCookieString += "&Ticket=" + manCookieObject.Ticket;
        return manCookieString;
    }
}

/**
 * Clear Javascript Intervals.
 * @param options - The intervals are stored in player configuration options.
 */
function clearJavascriptIntervals(options, logger) {
    if (options) {
        logger.log('Clearing all intervals');
        clearManCookieInterval(options, logger);
        clearPulseCallInterval(options, logger);
    }
}

function clearManCookieInterval(options, logger) {
    if (options && options.hasOwnProperty('drm') && options.drm.hasOwnProperty('clearManCookieInterval')) {
        options.drm.clearManCookieInterval();
        logger.log('MAN cookie interval cleared');
    }
}

function clearPulseCallInterval(options, logger) {
    if (options && options.hasOwnProperty('drm') && options.drm.hasOwnProperty('clearPulseCallInterval')) {
        options.drm.clearPulseCallInterval();
        logger.log('Pulse interval cleared');
    }
}

module.exports = {
    populateManCookie: populateManCookie,
    setupManCookieChangeChecks: setupManCookieChangeChecks,
    clearJavascriptIntervals: clearJavascriptIntervals
};