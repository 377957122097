/**
 * Constants to be used within tracking.
 */

module.exports = {
    
    // adobe events
    PLAY              : 'videoplay',
    PAUSE             : 'videopause',
    UNPAUSE           : 'videounpause',
    STOP              : 'videostop',
    COMPLETE          : 'videocomplete',
    SEEK              : 'videoseek',
    FULLSCREEN        : 'videofullscreen',
    FULLSCREEN_EXIT   : 'videofullscreenexit',

    TIME_PLAYED       : 'videotimeplayed',
    REWIND            : 'videorewind',
    REPLAY            : 'videoreplay',
    SHARE             : 'videoshare',

    MILESTONE25       : 'videomilestone25',
    MILESTONE50       : 'videomilestone50',
    MILESTONE75       : 'videomilestone75',
    MILESTONE90       : 'videomilestone90',

    ERROR             : 'videoError',
};