/**
 * Player implementation class for JW Player.
 */

var $ = require('jquery');
var bowser = require('bowser');

// var asyncRequire = require('../../utils/async-require.js');
var attachFairPlayLicensor = require('../../utils/fairplay-licensor.js');
var constants = require('../../config/constants.js');
var utils = require('../../utils/misc.js');
var manCookie = require('../../utils/man-cookie.js');
var requireJsHack = require('../../utils/require-js-hack.js');
var config = require('../../config/config.js');
var analytics = require('../../analytics/analytics.js');
var event = require('../../analytics/events-graylog.js');
var eventjw = require('./events-jw.js');

var playerjw;

/**
 * Initialises JW Player within the given DOM element using the specified options.
 * @param {string} el The ID of the target element into which to inject the JW Player instance.
 * @param {object} options The configuration options for the player.
 * @param {object} logger Our logging functions.
 */
function initJwPlayer(el, options, logger) {
    logger.log('Loading JW Player');

    // TODO: Remove this once JW Player fixes this issue
    // requireJsHack.installFakeRequireJS();

    // fetchJwPlayer(el, options, logger);
    onJwPlayerLoaded(el, options, logger);
}

/**
 * Attempts to asynchronously fetch JW Player and kick off its configuration.
 * @param {string} el The ID of the element into which to inject the JW Player instance.
 * @param {object} options The configuration options for the player.
 * @param {object} logger Our logging functions.
 */
// function fetchJwPlayer(el, options, logger) {
//     asyncRequire(
//         options.dependencies.jwplayer,
//         function() { onJwPlayerLoaded(el, options, logger); },
//         logger
//     );
// }

/**
 * Called once JW Player's scripting source has been loaded.
 * @param {string} el The ID of the element into which to inject the JW Player instance.
 * @param {object} options The configuration options for the player.
 * @param {object} logger Our logging functions.
 */
function onJwPlayerLoaded(el, options, logger) {

    loadJwPlayerIcons(options);
    loadTracking(options, logger);

    jwplayer.key = constants.JWPLAYER_LICENSE_KEY;

    // try our require-js hack again, just in case
    // requireJsHack.installFakeRequireJS();

    if (options.drm && bowser.safari) {

        logger.log('Initialising JW Player for Safari');
        initJwPlayerSafari(el, options, logger);

    } else if (options.drm && (bowser.msie || bowser.msedge)) {

        logger.log('Initialising JW Player for Microsoft browsers');
        initJwPlayerIE(el, options, logger);

    } else {

        logger.log('Initialising JW Player for Chrome/Firefox/etc. and non-DRM content');
        initJwPlayerChrome(el, options, logger);

    }
}

function loadJwPlayerIcons(options) {
    $('body').append('<link rel="stylesheet" href="'+
        utils.makeAbsoluteUrl(constants.JWPLAYER_ICONS_CSS, options.dependencyBasePath) +
        '" type="text/css">');
}

function loadTracking(options, logger) {
    if (options.meta != null) {
        logger.log('Instantiating Analytics with configuration object:', options.meta);
        analytics.init(options.meta, options.live, logger);
    } else {
        logger.log('Analytics not configured');
    }
}

/**
 * Configures DRM'd JW Player for Safari, using a custom license acquisition mechanism.
 * @param el
 * @param options
 * @param logger
 */
function initJwPlayerSafari(el, options, logger) {
    var config = constructJwPlayerConfig(options, logger);
    playerjw = jwplayer(el);
    playerjw.setup(config).on('ready', function() {
        logger.log('Attaching FairPlay license acquisition override code');
        attachFairPlayLicensor(
            el,
            config.drm.fairplay.keyUrl,
            config.drm.fairplay.url,
            logger
        );

        onFinishedLoading(options, logger);
    });
}


/**
 * Configures DRM'd JW Player for Microsoft Internet Explorer and Edge, using our hacky workaround
 * for the stupid error in JW Player where it can't find our DRM config.
 * @param el
 * @param options
 * @param logger
 */
function initJwPlayerIE(el, options, logger) {
    var configWithDrm = constructJwPlayerConfig(options, logger);
    var configWithoutDrm = utils.copyObjectWithoutProps(configWithDrm, ['drm']);

    // first instantiate the player without DRM configuration
    playerjw = jwplayer(el);
    playerjw.setup(configWithoutDrm).on('ready', function() {
        logger.log('Reloading JW Player with DRM support');
        jwplayer(el).setup(configWithDrm).on('ready', function() {
            onFinishedLoading(options, logger);
        });
    });
}


/**
 * Configures DRM'd JW Player for Chrome/Firefox/etc. (basically anything not MSIE/Edge/Safari).
 * @param el
 * @param options
 * @param logger
 */
function initJwPlayerChrome(el, options, logger) {
    var config = constructJwPlayerConfig(options, logger);

    playerjw = jwplayer(el);
    playerjw.setup(config).on('ready', function() {
        onFinishedLoading(el, options, logger);
    });
}


/**
 * Called when JW Player has been completely initialised.
 */
function onFinishedLoading(el, options, logger) {
    constructJwPlayerEvents(el, options, logger);

    // pulseCall for concurrency
    if (options.events && options.events.pulse) {
        var intervalId = setInterval(function() {
            pulseCall(options.events.pulse);
        }, constants.PULSE_DURATION_IN_SECONDS * 1000);

        options.drm.clearPulseCallInterval = function () {
            clearInterval(intervalId);
        };
    }

    // Restoring require.js
    // logger.log('Restoring require.js');
    // requireJsHack.restoreRequireJS();
}


function constructJwPlayerConfig(options, logger) {
    // first configure JW Player's configuration options
    var jwPlayerConfig = {
        image: config.getPlayerOpt(options, 'image'),
        file: config.constructSourceUrl(options, logger),
        autostart: config.getPlayerOpt(options, 'autoStart'),
        live: config.getPlayerOpt(options, 'live'),
        primary: 'html5'
    };

    // configuration options for hls streams
    if (options.url.indexOf(constants.MEDIA_TYPE_EXT_HLS) != -1) {
        jwPlayerConfig.hlshtml = true;
    }

    var height = config.getPlayerOpt(options, 'height');
    var width = config.getPlayerOpt(options, 'width', constants.PLAYER_WIDTH_DEFAULT);
    var aspectRatio = config.getPlayerOpt(options, 'aspectratio', constants.PLAYER_ASPECT_RATIO_DEFAULT);

    if (width) {
        jwPlayerConfig.width = width;
    } else if (height) {
        jwPlayerConfig.height = height;
    }
    if (aspectRatio) {
        jwPlayerConfig.aspectratio = aspectRatio;
    }
    if (options.stretching) {
        jwPlayerConfig.stretching = options.stretching;
    }

    // if we're using a skin here
    if (options.skin) {
        jwPlayerConfig.skin = {
            name: options.skin,
            url: utils.makeAbsoluteUrl(constants.PLAYER_DEPENDENCIES.jwplayer.skins[options.skin], options.dependencyBasePath)
        };
    }

    // if we're using a DRM'd asset here
    if (options.drm) {
        jwPlayerConfig.drm = constructJwPlayerDrmConfig(options, logger);
        // clear out the irrelevant DRM config (causes some versions of JW Player to break)
        if (bowser.chrome) {
            delete jwPlayerConfig.drm.playready;
            delete jwPlayerConfig.drm.fairplay;
        } else if (bowser.safari) {
            delete jwPlayerConfig.drm.widevine;
            delete jwPlayerConfig.drm.playready;
        } else {
            delete jwPlayerConfig.drm.widevine;
            delete jwPlayerConfig.drm.fairplay;
        }
    }

    // check for advertising
    if (options.ads) {
        jwPlayerConfig.advertising = constructJwPlayerAdsConfig(options, logger);
    }

    logger.log('Constructed JW Player config:', jwPlayerConfig);
    return jwPlayerConfig;
}

function constructJwPlayerDrmConfig(options, logger) {
    logger.log((options.drm.manCookie == '') ? 'No MAN Cookie Available...' : '');
    var servers = constants.DRM_SERVERS_URLS[options.drm.profile];

    return {
        widevine: {
            url: utils.quickTemplateRender(servers.widevine, options.drm) + options.drm.manCookie
        },
        playready: {
            url: utils.quickTemplateRender(servers.playready, options.drm) + options.drm.manCookie
        },
        fairplay: {
            processSpcUrl: utils.quickTemplateRender(servers.fairplay.url, options.drm) + options.drm.manCookie,
            certificateUrl: utils.quickTemplateRender(servers.fairplay.keyUrl, options.drm),
            extractContentId: function(initDataUri) {
                return initDataUri.split('skd://')[1];
            },
            licenseRequestHeaders: [],
            licenseResponseType: 'arraybuffer',
            licenseRequestMessage: function(message) {
                return message;
            },
            extractKey: function(ckc) {
                return new Uint8Array(ckc);
            }
        }
    };
}

function constructJwPlayerAdsConfig(options, logger) {
    return {
        client: 'googima',
        schedule: {
            preroll: {
                offset: 'pre',
                tag: options.ads.preAdURL
            },
            postroll: {
                offset: 'post',
                tag: options.ads.postAdURL
            }
        }
    };
}

function constructJwPlayerEvents(el, options, logger) {
    // https://developer.jwplayer.com/jw-player/docs/developer-guide/api/javascript_api_introduction/
    // Setup
    jwplayer().on(eventjw.READY, function(e) {
        analytics.track(event.READY, logger);
        utils.triggerEvent(options.events, constants.EVENTS.READY);
    });
    jwplayer().on(eventjw.SETUP_ERROR, function(e) {
        analytics.error('setup error: player settings setup incorrectly', logger);
        utils.triggerEvent(options.events, constants.EVENTS.ERROR, e);
    });

    // Playback
    jwplayer().on(eventjw.PLAY, function(e) {
        analytics.track(event.PLAY, logger);
        utils.triggerEvent(options.events, constants.EVENTS.PLAY);
    });
    jwplayer().on(eventjw.PAUSE, function(e) {
        analytics.track(event.PAUSE, logger);
        utils.triggerEvent(options.events, constants.EVENTS.PAUSE);
    });
    jwplayer().on(eventjw.BUFFER, function(e) {
        analytics.track(event.BUFFER, logger);
        utils.triggerEvent(options.events, constants.EVENTS.BUFFER);
    });
    jwplayer().on(eventjw.IDLE, function(e) {
        analytics.track(event.IDLE, logger);
    });
    jwplayer().on(eventjw.COMPLETE, function(e) {
        analytics.track(event.COMPLETE, logger);
        utils.triggerEvent(options.events, constants.EVENTS.COMPLETE);
    });
    jwplayer().on(eventjw.ERROR, function(e) {
        analytics.error(e.message, logger);
        utils.triggerEvent(options.events, constants.EVENTS.ERROR, e);
    });

    // Seek
    jwplayer().on(eventjw.SEEK, function(e) {
        analytics.track(event.SEEK, logger);
        utils.triggerEvent(options.events, constants.EVENTS.SEEK);
    });
    jwplayer().on(eventjw.TIME, function(e) {
        analytics.track(event.TIME, logger, e);
    });

    // Resize
    jwplayer().on(eventjw.FULLSCREEN, function(e) {
        analytics.track(event.FULLSCREEN, logger, e);
        utils.triggerEvent(options.events, constants.EVENTS.FULLSCREEN, null);
    });

    // Quality
    jwplayer().on(eventjw.LEVELS, function(e) {
        // Levels Event
        // for (var key in e.levels) {
        //     if (e.levels.hasOwnProperty(key)) {
        //         console.log(key + ': ' + e.levels[key].label);
        //     }
        // }
    });
    jwplayer().on(eventjw.LEVELSCHANGED, function(e) {
        // LevelsChanged Event
        var index = jwplayer().getCurrentQuality();
        var bitrate = e.levels[index].label;
        logger.log('// LevelsChanged: ' + bitrate);
    });
}

function pulseCall(callback) {
    if (playerjw && isPlaying() && !isPaused()) {
        if (callback) {
            callback();
        }
    }
}

function isPlaying() {
    return (isPlayerAvailable() && playerjw.getState() == 'playing') ? true : false;
}

function isPaused() {
    return (isPlayerAvailable() && playerjw.getState() == 'paused') ? true : false;
}

function isPlayerAvailable() {
    return (playerjw != null && typeof playerjw != 'undefined') ? true : false;
}

function destroyJwPlayer(el, options, logger) {
    if (isPlayerAvailable()) {
        logger.log('*** JW Player has been destroyed ***');

        playerjw = null;
        jwplayer(el).remove();
        manCookie.clearJavascriptIntervals(options, logger);
    } else {
        logger.log('*** JW Player does not exist ***');
    }
}

module.exports = {
    init: initJwPlayer,
    destroy: destroyJwPlayer,
    isPlaying : isPlaying,
    isPaused : isPaused
};