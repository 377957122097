/**
 * Wrapper for using Bitmovin's player (Bitdash): https://bitmovin.com/
 */
var $ = require('jquery');
var bowser = require('bowser');

var config = require('../../config/config.js');
var constants = require('../../config/constants.js');
var utils = require('../../utils/misc.js');
var manCookie = require('../../utils/man-cookie.js');
var analytics = require('../../analytics/analytics.js');
var event = require('../../analytics/events-graylog.js');
var renderHtml = require('../../utils/render-html.js');

var player;

/**
 * Initialises Bitmovin's player as our player of choice.
 * @param {string} el The ID of the target div element into which to inject the player.
 * @param {object} options The configuration options for the player.
 * @param {object} logger Our logging functions.
 */
function initBitmovin(el, options, logger) {
    logger.log('Initialising Bitmovin player');

    onBitmovinLoaded(el, options, logger);
}

function onBitmovinLoaded(el, options, logger) {
    loadTracking(options, logger);
    var configObject = constructBitmovinConfig(el, options, logger);

    logger.log('Initiating Bitmovin player setup', configObject);
    player = window.bitdash(el);
    player.setup(configObject).then(function(value) {
        adjustBitmovinStyling(el, logger);
    }, function(reason) {
        logger.log('Error while attempting to instantiate Bitmovin player:', reason);
        analytics.error(reason, logger);
        renderHtml.message(el, reason);
    });
}

function loadTracking(options, logger) {
    if (options.meta != null) {
        logger.log('Instantiating Analytics with configuration object:', options.meta);
        analytics.init(options.meta, options.live, logger);
    } else {
        logger.log('Analytics not configured');
    }
}

function adjustBitmovinStyling(el, logger) {
    logger.log('adding style to "div.bitdash-poster img"');
    $('div.bitdash-poster img').css('width', '100%');
    $('#bitdash-video-' + el).css('left', '0px !important');
}

/**
 * Builds up our Bitmovin player's configuration from the generic player configuration.
 * @param {object} options Our overall player configuration.
 * @param {object} logger Our logging configuration.
 * @returns {object} The Bitmovin player configuration.
 */
function constructBitmovinConfig(el, options, logger) {
    var cfg = {
        key: constants.BITMOVIN_LICENSE_KEY,
        tweaks : {
            context_menu_entries: []
        }
    };
    var mediaType = config.inferMediaType(options.url, logger);
    if (mediaType == constants.MEDIA_TYPE_DYNAMIC) {
        cfg.source = {
            dash: options.url+"/.mpd",
            hls: options.url+"/.m3u8"
        };
    } else if (mediaType == constants.MEDIA_TYPE_MPEG_DASH) {
        cfg.source = {
            dash: options.url
        };
    } else if (mediaType == constants.MEDIA_TYPE_HLS) {
        cfg.source = {
            hls: options.url
        };
    } else {
        logger.log("Assuming medium is MP4 (progressive)");
        cfg.source = {
            progressive: options.url
        };
    }

    // if we have a poster image for the asset
    if (options.image) {
        cfg.source.poster = options.image;
    }

    // figure out how to configure our DRM
    if (options.drm) {
        cfg.source.drm = constructBitmovinDrmConfig(options, logger);
    }

    cfg.playback = {
        timeShift: (options.live) ? false : true,
        autoplay: config.getPlayerOpt(options, 'autoplay', options.autoStart)
    };

    // create video style options
    cfg.style = {};
    cfg.style.subtitlesHidden = true;

    var height = config.getPlayerOpt(options, 'height');
    var width = config.getPlayerOpt(options, 'width', constants.PLAYER_WIDTH_DEFAULT);
    var aspectRatio = config.getPlayerOpt(options, 'aspectratio', constants.PLAYER_ASPECT_RATIO_DEFAULT);

    if (width) {
        cfg.style.width = width;
    } else if (height) {
        cfg.style.height = height;
    }
    if (aspectRatio) {
        cfg.style.aspectratio = aspectRatio;
    }

    // if we're using a skin here
    if (options.skin) {
        cfg.skin = utils.makeAbsoluteUrl(constants.PLAYER_DEPENDENCIES.bitmovin.skins[options.skin], options.dependencyBasePath);
    }

    // for player event tracking
    cfg.events = contructBitMovinEvents(el, options, logger);

    // pulseCall for concurrency
    if (options.events && options.events.pulse) {
        var intervalId = setInterval(function() {
            pulseCall(options.events.pulse);
        }, constants.PULSE_DURATION_IN_SECONDS * 1000);

        options.drm.clearPulseCallInterval = function () {
            clearInterval(intervalId);
        };
    }

    return cfg;
}

function constructBitmovinDrmConfig(options, logger) {
    logger.log((options.drm.manCookie == '') ? 'No MAN Cookie Available...' : '');
    var servers = constants.DRM_SERVERS_URLS[options.drm.profile];

    return {
        widevine: {
            LA_URL: utils.quickTemplateRender(servers.widevine, options.drm) + options.drm.manCookie
        },
        playready: {
            LA_URL: utils.quickTemplateRender(servers.playready, options.drm) + options.drm.manCookie
        },
        fairplay: {
            LA_URL: utils.quickTemplateRender(servers.fairplay.url, options.drm) + options.drm.manCookie,
            certificateURL: utils.quickTemplateRender(servers.fairplay.keyUrl, options.drm),
            prepareContentId: function (contentId) {
                return contentId.match(/ContentId=([^&]+)/)[1];
            },
            prepareMessage: function (event, session) {
                return new Uint8Array(event.message);
            },
            prepareLicense: function (license) {
                return new Uint8Array(license);
            },
            licenseResponseType: 'arraybuffer'
        }
    };
}

function contructBitMovinEvents(el, options, logger) {
    return {
        // Setup
        onReady : function(e) {
            analytics.track(event.READY, logger);
            utils.triggerEvent(options.events, constants.EVENTS.READY);
        },

        // Playback
        onPlay : function(e) {
            analytics.track(event.PLAY, logger);
            utils.triggerEvent(options.events, constants.EVENTS.PLAY);
        },
        onPause : function() {
            analytics.track(event.PAUSE, logger);
            utils.triggerEvent(options.events, constants.EVENTS.PAUSE);
        },
        onStartBuffering : function() {
            analytics.track(event.BUFFER, logger);
            utils.triggerEvent(options.events, constants.EVENTS.BUFFER);
        },
        onPlaybackFinished : function() {
            analytics.track(event.COMPLETE, logger);
            utils.triggerEvent(options.events, constants.EVENTS.COMPLETE);
        },
        onError : function(e) {
            handleErrorMessage(el, e.code);
            destroyBitmovin(el, options, logger);

            analytics.error(e.message, logger);
            utils.triggerEvent(options.events, constants.EVENTS.ERROR, e);
        },

        // Seek
        onSeek : function(e) {
            analytics.track(event.SEEK, logger);
            utils.triggerEvent(options.events, constants.EVENTS.SEEK);
        },
        onTimeChanged : function(e) {
            var time = {
                position : player.getCurrentTime(),
                duration : player.getDuration()
            };
            analytics.track(event.TIME, logger, time);
        },

        // Resize
        onFullscreenEnter : function() {
            var obj = {
                fullscreen : true
            };
            analytics.track(event.FULLSCREEN, logger, obj);
            utils.triggerEvent(options.events, constants.EVENTS.FULLSCREEN, true);
        },
        onFullscreenExit : function() {
            analytics.track(event.FULLSCREEN_EXIT, logger);
            utils.triggerEvent(options.events, constants.EVENTS.FULLSCREEN, false);
        },

        // Quality
        onVideoPlaybackQualityChange : function() {
            // logger.log('// onVideoPlaybackQualityChange');
        }
    };
}

function handleErrorMessage(el, code) {
    switch (code) {
        case 3023:
            renderHtml.messageWithErrorCode(el, constants.MESSAGES.NO_NETWORK_AVAILABLE, code);
            break;
        default:
            renderHtml.messageWithErrorCode(el, constants.MESSAGES.NO_ASSET_AVAILABLE, code);
            break;
    }
}

function pulseCall(callback) {
    if (player && isPlaying() && !isPaused()) {
        if (callback) {
            callback();
        }
    }
}

function isPlaying() {
    return (isPlayerAvailable() && player.hasOwnProperty('isPlaying')) ? player.isPlaying() : false;
}

function isPaused() {
    return (isPlayerAvailable() && player.hasOwnProperty('isPaused')) ? player.isPaused() : false;
}

function isPlayerAvailable() {
    return (player != null && typeof player != 'undefined') ? true : false;
}

function destroyBitmovin(el, options, logger) {
    if (isPlayerAvailable() && window.bitdash && typeof window.bitdash(el) != 'undefined') {
        logger.log('*** Bitmovin Player has been destroyed ***');

        player = null;
        window.bitdash(el).destroy();
        manCookie.clearJavascriptIntervals(options, logger);
    } else {
        logger.log('*** Bitmovin Player does not exist ***');
    }
}

module.exports = {
    init: initBitmovin,
    destroy: destroyBitmovin,
    isPlaying : isPlaying,
    isPaused : isPaused
};