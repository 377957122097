/*
 * Miscellaneous functions for the DStv HTML5 Video Player.
 */

function stringToArray(string) {
    var buffer = new ArrayBuffer(string.length * 2); // 2 bytes for each char
    var array = new Uint16Array(buffer);
    for (var i = 0, strLen = string.length; i < strLen; i++) {
        array[i] = string.charCodeAt(i);
    }
    return array;
}

function arrayToString(array) {
    var uint16array = new Uint16Array(array.buffer);
    uint16array = uint16array.subarray(1, uint16array.length);
    return String.fromCharCode.apply(null, uint16array);
}

function base64DecodeUint8Array(input) {
    var raw = window.atob(input);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for (i = 0; i < rawLength; i++)
        array[i] = raw.charCodeAt(i);

    return array;
}

function base64EncodeUint8Array(input) {
    var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    var output = "";
    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    var i = 0;

    while (i < input.length) {
        chr1 = input[i++];
        chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index
        chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here

        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;

        if (isNaN(chr2)) {
            enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
            enc4 = 64;
        }
        output += keyStr.charAt(enc1) + keyStr.charAt(enc2) +
            keyStr.charAt(enc3) + keyStr.charAt(enc4);
    }
    return output;
}

/**
 * Strips trailing forward slashes from the given string.
 * @param s The source string.
 * @return {string} The stripped resultant string.
 */
function stripTrailingSlashes(s) {
    while (s && s.length > 0 && s[s.length-1] == '/') {
        s = s.substr(0, s.length-1);
    }
    return s;
}


/**
 * Checks if the specified URL is relative, and, if so, makes it absolute.
 * @param {string} url The URL to check.
 * @param {string} baseUrl The base URL to prepend to the URL if the source URL is relative.
 * @return {string} The absolute URL.
 */
function makeAbsoluteUrl(url, baseUrl) {
    // if it's an absolute URL
    if (url.lastIndexOf('https://', 0) == 0 || url.lastIndexOf('http://', 0) == 0 || url.lastIndexOf('//', 0) == 0) {
        return url;
    } else {
        // if it's relative
        if (url.indexOf(baseUrl) >= 0) {
            return url;
        } else {
            return baseUrl + '/' + url;
        }
    }
}


/**
 * Takes the given object (containing URLs) and, if relative, makes them absolute.
 * @param urls
 * @param baseUrl
 */
function makeAbsoluteUrls(urls, baseUrl) {
    var result = {};

    for (var urlName in urls) {
        if (urls.hasOwnProperty(urlName)) {
            if (urls[urlName] && urls[urlName].length > 0) {
                url = urls[urlName];
                result[urlName] = makeAbsoluteUrl(url, baseUrl);
            }
        }
    }
    return result;
}

/**
 * Merges the given source object into the destination object and returns the result.
 * @param dest The target object into which the source object will be merged.
 * @param src Overrides destination object properties.
 * @return object The merged object.
 */
function mergeObjects(dest, src) {
    var result = {};

    // shallow copy the destination object into the result
    for (var destProp in dest) {
        if (dest.hasOwnProperty(destProp)) {
            result[destProp] = dest[destProp];
        }
    }

    // shallow copy the source object into the result
    for (var srcProp in src) {
        if (src.hasOwnProperty(srcProp)) {
            result[srcProp] = src[srcProp];
        }
    }

    return result;
}

/**
 * Quick n dirty template renderer - poor man's Mustache.js for rendering URLs :-)
 * @param template The template string to use.
 * @param vars The variables to insert into the template string.
 * @return string
 */
function quickTemplateRender(template, vars) {
    var result = template;

    for (var varName in vars) {
        if (vars.hasOwnProperty(varName)) {
            result = result.replace(new RegExp("\\{\\{"+varName+"\\}\\}", "g"), encodeURIComponent(vars[varName]));
        }
    }

    return result;
}

/**
 * Extracts the file extension from the given URL.
 * @param url The URL from which to extract its extension.
 * @return string
 */
function extractUrlExtension(url) {
    var urlParts = url.split('.');
    if (!urlParts || urlParts.length == 0) {
        return '';
    }
    return urlParts[urlParts.length-1].toLowerCase();
}


/**
 * Shallow-copies the given source object, but leaving out the specified properties.
 * @param src
 * @param skipProps An array of the properties to skip.
 * @return string
 */
function copyObjectWithoutProps(src, skipProps) {
    var result = {};

    for (var propName in src) {
        if (src.hasOwnProperty(propName) && skipProps.indexOf(propName) == -1) {
            result[propName] = src[propName];
        }
    }

    return result;
}

function getOrDefault(obj, propName, defVal) {
    return (typeof obj === 'object' && obj.hasOwnProperty(propName)) ? obj[propName] : defVal;
}


function extractFilenameFromUrl(url) {
    var urlParts = url.split('?')[0].split('/');
    if (urlParts.length == 0) {
        return null;
    }
    // get the filename plus extension
    var fullFilename = urlParts[urlParts.length-1];
    var extPos = fullFilename.lastIndexOf('.');
    // return filename minus extension
    return extPos == -1 ? fullFilename : fullFilename.substr(0, extPos);
}


function getExtension(s) {
    var extPos = s.lastIndexOf('.');
    return extPos == -1 ? null : s.substr(extPos+1).toLowerCase();
}


/**
 * Attempts to extract the DRM content ID from the given URL. Assumes that the content ID
 * is contained in the parent folder name of the ISM/ISML file in the path. For example,
 * the content ID for the URL http://v.dstv.com/Catchup/STREAMING/2016/02/08/12345/manifest.ism/.mpd
 * will be 12345.
 * @param url The URL from which to extract the content ID.
 * @returns {*} On success, a string containing the content ID. On failure, returns null.
 */
function extractContentIdFromUrl(url) {
    if (!url) {
        return null;
    }
    var urlParts = url.split('?')[0].split('/');
    if (urlParts.length == 0) {
        return null;
    }
    var curPart = urlParts.length-1;
    var curExt = getExtension(urlParts[curPart]);
    while (curPart > 0 && curExt != 'ism' && curExt != 'isml') {
        curPart--;
        curExt = getExtension(urlParts[curPart]);
    }
    // if we have a parent folder of some kind
    if (curPart >= 1) {
        return urlParts[curPart-1];
    }
    return null;
}


/**
 * Escapes strings that should be considered as HTML attributes' contents (e.g. a URL that must go into
 * an HTML tag).
 * @param s The string to escape.
 * @returns {string} The escaped string.
 */
function escapeHtmlAttr(s) {
    return s.replace(/(['"])/g, "\\$1");
}


/**
 * Converts the specified width and aspect ratio into a height.
 * @param {number} width The width to convert.
 * @param {string} aspectRatio A string representing the aspect ratio (e.g. "16:9").
 * @returns {number|null} On success, the calculated height. On failure, returns null.
 */
function heightFromAspectRatio(width, aspectRatio) {
    var aspectRatioParts = null;
    var height = null;

    if (typeof aspectRatio === 'string') {
        aspectRatioParts = aspectRatio.split(':');
    }

    if (aspectRatio && aspectRatioParts.length == 2) {
        var ratio = parseFloat(aspectRatioParts[0]) / parseFloat(aspectRatioParts[1]);
        height = Math.round(width / ratio);
    }

    return height;
}


/**
 * Parses the given string, assuming it's in URI format.
 * @param s
 * @returns {{}}
 */
function extractQueryParamsFromString(s) {
    var params = {};
    var parts = s.split('&');

    for (var i = 0; i < parts.length; i++) {
        var nv = parts[i].split('=');
        if (!nv[0]) continue;
        params[nv[0]] = decodeURIComponent(nv[1]) || true;
    }

    return params;
}


/**
 * Extracts query parameters from the window.location.search property.
 * @returns {object} An object whose properties are the query parameter names, and values are the query parameter values.
 */
function extractQueryParams() {
    var params = {};

    if (window.location.search) {
        params = extractQueryParamsFromString(window.location.search.substring(1));
    }

    return params;
}

function stripDrmConfigFromUrlForFeed(options, feedData) {
    options.url = feedData.services.videoURL;
    options = stripDrmConfigFromUrl(options);
    feedData.services.videoURL = options.url;
    return options;
}

function stripDrmConfigFromUrl(options) {
    if(typeof options.drm === "boolean") {
        options.drm = {};

        options.url = options.url.replace('/?', '?');
        var parts = options.url.split('?');

        // always strip out any query string parameters
        options.url = parts[0];

        if(parts.length === 2) {
            var strippedContent = extractQueryParamsFromString(parts[1]);
            if (strippedContent.hasOwnProperty('contentId')) {
                options.drm.contentId = strippedContent.contentId;
                if (strippedContent.hasOwnProperty('keyId')) {
                    options.drm.keyId = strippedContent.keyId;
                }
            }
        }

        // try to extract the content ID from the URL itself
        if (!options.drm.hasOwnProperty('contentId')) {
            options.drm.contentId = extractContentIdFromUrl(parts[0]);
        }
    }

    return options;
}

/**
 * Helper function to trigger an event.
 * @param {object} events An object containing our various different events.
 * @param {string} eventName The name of the event, in our event object, to attempt to trigger.
 * @param {*} params Any additional parameters to pass through to the event handler.
 * @param {function} fallback The fallback function if no event has been defined.
 */
function triggerEvent(events, eventName, params, fallback) {
    if (events && events.hasOwnProperty(eventName) && typeof events[eventName] === 'function') {
        events[eventName](params);
    } else if (fallback) {
        fallback(params);
    }
}

module.exports = {
    stringToArray: stringToArray,
    arrayToString: arrayToString,
    base64DecodeUint8Array: base64DecodeUint8Array,
    base64EncodeUint8Array: base64EncodeUint8Array,
    stripTrailingSlashes: stripTrailingSlashes,
    makeAbsoluteUrl: makeAbsoluteUrl,
    makeAbsoluteUrls: makeAbsoluteUrls,
    mergeObjects: mergeObjects,
    quickTemplateRender: quickTemplateRender,
    extractUrlExtension: extractUrlExtension,
    copyObjectWithoutProps: copyObjectWithoutProps,
    getOrDefault: getOrDefault,
    extractFilenameFromUrl: extractFilenameFromUrl,
    escapeHtmlAttr: escapeHtmlAttr,
    heightFromAspectRatio: heightFromAspectRatio,
    extractQueryParamsFromString: extractQueryParamsFromString,
    extractQueryParams: extractQueryParams,
    stripDrmConfigFromUrl: stripDrmConfigFromUrl,
    stripDrmConfigFromUrlForFeed: stripDrmConfigFromUrlForFeed,
    extractContentIdFromUrl: extractContentIdFromUrl,
    triggerEvent: triggerEvent
};
