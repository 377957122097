/**
 * Constants to be used JWPlayer.
 */

module.exports = {

    // jw player events
    PLAY              : 'play',
    PAUSE             : 'pause',
    UNPAUSE           : 'unpause',
    BUFFER            : 'buffer',
    IDLE              : 'idle',
    COMPLETE          : 'complete',
    SEEK              : 'seek',
    TIME              : 'time',
    FULLSCREEN        : 'fullscreen',
    FULLSCREEN_EXIT   : 'fullscreenexit',

    TIME_PLAYED       : 'timeplayed',
    REWIND            : 'rewind',
    REPLAY            : 'replay',
    SHARE             : 'share',

    MILESTONE25       : 'milestone25',
    MILESTONE50       : 'milestone50',
    MILESTONE75       : 'milestone75',
    MILESTONE90       : 'milestone90',

    READY             : 'ready',
    SETUP_ERROR       : 'setupError',
    ERROR             : 'error',
    META              : 'meta',

    LEVELS            : 'levels',
    LEVELSCHANGED     : 'levelsChanged'
};